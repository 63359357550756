import React from 'react';
import BasicButton from "../Buttons/BasicButton";
import { Link } from "react-router-dom";
import Image from '../LazyLoadImage';
import NotFound from "../../../images/image-not-found.png"

const Thumbnails = ({title, image, preview_info, link, button_text, nombreEsquema, esquema}) => {
    return(
        <Link to={link} style={{ textDecoration: 'none' }}>
            <div className={`relative overflow-hidden cursor-pointer flex-col text-base rounded-xl bg-gray-100 shadow-lg border-2 border-gray-200 hover:border-primary-100`}>
                <div className={`${esquema === 2 ? 'bg-primary-500' : 'bg-secondary-100'} absolute transform rotate-45 text-center text-[10px] text-white font-light h-[20px] right-[-28px] top-[12px] w-[100px]`} >
                    {nombreEsquema}
                </div>
                <div className="flex justify-center text-center text-lg p-2 bg-primary-100 rounded-t-lg text-secondary-400 h-[70px] px-10">
                    {title}
                </div>
                <div className="flex justify-center bg-white w-full h-100">
                    {
                        image !== undefined && image !== null && image !== "" ?
                            <Image
                                src={image.url}
                                alt="MERENT"
                                className={"object-cover h-80 w-screen m-0"}
                            />
                        :
                            <Image
                                src={NotFound}
                                alt="Imagen no encontrada"
                                className={"object-cover h-80 w-screen m-0"}
                            />
                    }
                    
                </div>
                <div className="flex flex-col justify-left bg-gray-300 text-secondary-500 text-sm p-2">
                    {preview_info}
                </div>
                <div className="flex justify-center bg-gray-300 text-base rounded-b-xl p-0">
                    <BasicButton type="button" text={button_text} className="bg-primary-400 text-white mt-0 mb-0 hover:bg-primary-500"/>
                </div>
            </div>
        </Link>
    )
}

export default Thumbnails;