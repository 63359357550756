import React from "react";
import Layout from "../components/UI/Layout";
import Container from "../components/UI/Container";
import { Link } from "react-router-dom";
import Image from "../components/UI/LazyLoadImage";
import rentar_equipo from "../images/rentar_equipo.png";
import merent_logo from "../images/merent-logo.png"

const RentarEquipo = () => (
  <Layout>
    <Container> 
      <div className="p-5 text-lg rounded-xl bg-white shadow-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
          <Image
            src={rentar_equipo}
            alt="MERENT"
            width="100%"
          />
          <div className="">
            <div className="text-center font-semibold text-3xl my-4 text-primary-500">
            <Image
              src={merent_logo}
              alt="MERENT logo"
              width={350}
            />
            </div>
            <p className="m-5 text-base">
              Si estas buscando una maquina o equipo para desarrollar una tarea en específico, a través de nuestra aplicación encontraras diversas opciones y podrás escoger entre 2 esquemas de renta: <br/>
              <label className="font-semibold text-base my-3 text-primary-500"> Renta Segura: </label> Tratas directamente con Merent quien Administrara  la renta desde la contrataciòn hasta su termino. Un aviso le llegara a nuestro equipo, quien se pondra en contacto contigo.<br/>
              <label className="font-semibold text-base my-3 text-primary-500"> Renta Abierta: </label> Te proporcionamos los datos de contacto de un integrante de nuestra comunidad que tenga el equipo de tu interes las condiciones de la renta las pactaran directamente.
            </p>
          </div>
          </div>   
          <div className="mx-5">
            <div className="text-center font-semibold text-xl my-4 text-primary-500">
              Pasos a seguir para poder rentar un equipo:
            </div>
            <ol className="m-5 text-base list-decimal">
              <li className="font-extrabold text-xl">
                Darte de alta en la comunidad Merent <Link to="/login">Aquí</Link>. Es facil y rapido.
              </li>
              <li>Escoge entre nuestros 2 esquemas de Arrendamiento. 
                <ul className="ml-4 text-sm list-disc">
                  <li>
                    <label className="font-semibold text-sm my-3 text-primary-500"> Renta Segura: </label> Tratas directamente con Merent quien Administrara  la renta desde la contrataciòn hasta su termino. Un aviso le llegara a nuestro equipo, quien se pondra en contacto contigo.
                  </li>
                  <li>
                    <label className="font-semibold text-sm my-3 text-primary-500"> Renta Abierta: </label> Te proporcionamos los datos de contacto de un integrante de nuestra comunidad que tenga el equipo de tu interes las condiciones de la renta las pactaran directamente.
                  </li>
                </ul>
              </li>
              <li>
                Da click en la Maquina o Equipo de tu interes, si es Renta Abierta: obten datos de contacto y si es Renta Segura: un asesor se pondra en contacto contigo.
              </li>
              <li>
                Si no encuentras un Equipo, comunicate con nosotros y te ayudamos en la busqueda.
              </li>
            </ol>
          </div>
        </div>
    </Container>
  </Layout>
)

export default RentarEquipo;
