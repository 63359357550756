import React from "react";
import Layout from "../components/UI/Layout";
import Container from "../components/UI/Container";
import Image from "../components/UI/LazyLoadImage";
import banner from "../images/banner.png"

const QuienesSomos = () => (
  <Layout>
    <Container> 
        <div className="p-5 flex-col text-lg rounded-xl bg-white shadow-lg">
          <Image
            src={banner}
            alt="MERENT"
            width="100%"
            height="400px"
            className={"object-cover h-[400px] w-screen m-0"}
          />
          <div className="flex justify-center font-semibold text-3xl my-4 text-primary-500">
              ¿Quiénes somos?
          </div>
          <div className="p-2">
            Merent es una comunidad que facilita el encuentro de propietarios de maquinaria y equipo con los clientes usuarios. Esta comunidad nace como respuesta a una doble necesidad en diversos sectores industriales como el sector de la construcción, por un lado cubrir el requerimiento de maquinaria y equipo que necesita una constructora o un particular para la ejecucion de un trabajo y por otro la oportunidad de generar ingresos adicionales a su actividad preponderante poniendo a disposición del mercado la maquinaria y equipo ocioso con el que esta misma empresa o individuo cuenta. <br/>
            
            Conformamos una comunidad que colabora con un objetivo común, poner a la disposición de un mercado en específico la mayor oferta de maquinaria y equipo en arrendamiento por medio de una herramienta tecnologica fácil y ágil que integre tanto a particulares propietarios de maquinaria, como a las empresas especializadas en el arrendamiento de las mismas. <br/> <br/>

            Nuestra misión es ser una plataforma digital que enliste y ponga a disposición de una localidad la renta de la Maquinaria, los Equipos y las Herramientas con que cuenta, con el fin de generar ingresos adicionales a sus propietarios, facilitando el arrendamiento de sus equipos para que alcancen su máxima eficiencia de vida útil derivado de un uso activo de los mismos.  <br/> <br/>

            El equipo MeRent esta conformado por profesionales que tienen un gran sentido de compromiso a partir de un valor esencial de colaboración, la colaboración es el eje central de la Comunidad Merent, tanto los oferentes de maquinaria como los usuarios finales de estas son los elementos que le dan vida a esta comunidad desempeñando cualquiera de los roles, idealmente los dos.
          </div>
          {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-x-3 gap-y-3 mx-4 px-5">
            <div>
              <div className="flex justify-center font-semibold text-xl my-3 text-primary-500">
                  Misión
              </div>
              <div className="flex text-left">
                Tempor orci dapibus ultrices in iaculis nunc sed. Amet volutpat consequat mauris nunc congue. Id venenatis a condimentum vitae sapien pellentesque habitant. Morbi tincidunt augue interdum velit euismod in pellentesque massa placerat. Massa enim nec dui nunc mattis enim ut tellus. 
              </div>
            </div>
            <div>
              <div className="flex justify-center font-semibold text-xl my-3 text-primary-500">
                  Visión
              </div>
              <div className="flex text-left">
                Tellus rutrum tellus pellentesque eu tincidunt. Volutpat blandit aliquam etiam erat velit scelerisque. Felis bibendum ut tristique et egestas quis. Nisl tincidunt eget nullam non nisi est sit amet. Sapien faucibus et molestie ac feugiat sed lectus.
              </div>
            </div>
          </div> */}
        </div>   
    </Container>
  </Layout>
)

export default QuienesSomos;
