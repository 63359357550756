import React, {useState, useEffect} from "react";
import Layout from "../components/UI/Layout";
import Container from "../components/UI/Container";
import { merent_instance } from "../helpers/axios_helper";
import toast from "react-hot-toast";
import Busqueda from "../components/Busqueda";

const Favoritos = () => {
    const [equipos, setEquipos] = useState([]);
    const [equiposFiltered, setEquiposFiltered] = useState([]);
    const [favoritos, setFavoritos] = useState([]);

    useEffect(() => {
        cargaEquipos();
    }, []);

    useEffect(() => {
        if(favoritos.length <= 0)
            return;
        const c_equiposFiltered = equipos.filter(eq => {
            return favoritos.some((fav) => {
                return fav.id == eq.id;
            })
        });
        setEquiposFiltered(
            c_equiposFiltered
        )
    }, [favoritos])

    const cargaEquipos = () => {
        merent_instance().get("api/equipos").then(({data}) => {
            setEquipos(data);
            cargaFavoritos();
        }).catch((error) => {
            toast.error("Error al cargar equipos, intenta nuevamente más tarde");
        });
    }

    const cargaFavoritos = () => {
        const usr_id = localStorage.getItem("usr_id");
        if(usr_id === undefined || usr_id === null || usr_id === 0) {
            return;
        }
        merent_instance().get(`api/clientes/${usr_id}/favoritos/`)
        .then(({data}) => {
            setFavoritos(data);

        })
        .catch((error) => {
            console.log("Error al cargar favoritos");
        })
    }

    return(
        <Layout>
            <Container>
            <div className="p-5 flex-col text-lg rounded-xl bg-white shadow-lg">
                <div className="flex justify-center font-semibold text-3xl my-4 text-primary-400">
                    Mis favoritos
                </div>
                {equiposFiltered.length <= 0 ? 
                <div className="flex justify-center text-xl my-4 text-secondary-400">
                    Actualmente no cuentas con ningun favorito
                </div>
                :
                <Busqueda equipos={equiposFiltered}/>
                }
            </div>
            </Container>
        </Layout>
      )
  }
    
export default Favoritos;