import React, {useState, useEffect} from "react";
import Layout from "../components/UI/Layout";
import Container from "../components/UI/Container";
import BasicButton from "../components/UI/Buttons/BasicButton";
import Form from "../components/UI/Form";
import { form_fields_perfil, form_fields_perfil_direcciones } from '../helpers/form_fields';
import { dummy_data_rentas_detalle, dummy_data_rentas } from '../helpers/dummy_data';
import { grid_columns_rentas, grid_columns_operadores, grid_columns_tickets } from "../helpers/grid_columns";
import toast from 'react-hot-toast';
import Modal from '../components/UI/Modal';
import Address from '../components/UI/Address';
import Datagrid from '../components/UI/Datagrid';
import Rate from "../components/UI/Rate";
import { _ } from "gridjs-react";
import { useNavigate } from "react-router-dom";
import { merent_instance } from "../helpers/axios_helper";
import BasicInput from "../components/UI/Inputs/BasicInput";
import { FaPhone, FaEnvelope, FaMapMarkedAlt, FaRegCalendarAlt, FaWhatsapp } from "react-icons/fa";
import ShoppingCartElement from '../components/UI/ShoppingCartElement';
import { Calendar } from 'react-date-range';
import { es } from 'date-fns/locale';
import { format } from 'date-fns';
import Image from "../components/UI/LazyLoadImage";
import avatar from "../images/User-avatar.png";

const Perfil = () => {
  const navigation = useNavigate();
  const [showDireccion, setShowDireccion] = useState(false);
  const [showDeleteDireccion, setShowDeleteDireccion] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [solicitarSoporte, setSolicitarSoporte] = useState(false);
  const [rentas, setRentas] = useState([]);
  const [ticketsSoporte, setTicketsSoporte] = useState([]);
  const [operadoresContactados, setOperadoresContactados] = useState([]);
  const [motivosTicket, setMotivosTicket] = useState([]);

  const [operadorActual, setOperadorActual] = useState(false);
  const [calificarOperador, setCalificarOperador] = useState(false);
  const [calificacionOperador, setCalificacionOperador] = useState(0);

  const [rentaActual, setRentaActual] = useState({});
  const [calificarRenta, setCalificarRenta] = useState(false);
  const [calificacionRenta, setCalificacionRenta] = useState(0);
  const [comentariosRenta, setComentariosRenta] = useState("");

  const [extenderRenta, setExtenderRenta] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nuevaFecha, setNuevaFecha] = useState({});
  const [nuevaFechaFormat, setNuevaFechaFormat] = useState('');
  const [cantidadDias, setCantidadDias] = useState(0);
  const [cotizacion, setCotizacion] = useState({disponible: false, precio: 0, validado: false});

  const [direcciones, setDirecciones] = useState([]);
  const [estados, setEstados] = useState([]);
  const [ciudades, setCiudades] = useState([]);

  const [perfilFields, setPerfilFields] = useState({
      email: '',
      password: '',
      telefono: ''
  });

  const [direccionFields, setDireccionFields] = useState({
    id: 0,
    nombre: "",
    direccionCompleta: '',
    calle: '',
    numeroInterior: '',
    numeroExterior: '',
    colonia: '',
    ciudadRef: 0,
    estadoRef: 0,
    codigoPostal: 0,
    latitud: 0,
    longitud: 0,
    isPrincipal: true
  });

  const [ticketFields, setTicketFields] = useState({
      rentaRef: 0,
      motivoTicketRef: 0,
      comentarios: ''
  });

  useEffect(() => {
    const usr_email = localStorage.getItem("usr_email");
    const usr_phone = localStorage.getItem("usr_phone");

    setPerfilFields({...perfilFields, ...{email: usr_email, telefono: usr_phone}})

    cargarDireccionesCliente();
    cargarOperadoresContactados();
    cargarRentas();
    cargarMotivosTicket();
    cargarTicketsSoporte();
  }, []);
  
  const savePerfil = () => {
      toast.success("Perfil actualizado");
  };

  const cargarDireccionesCliente = () => {
    const usr_id = localStorage.getItem("usr_id");

    merent_instance().get(`/api/clientes/${usr_id}/direcciones`).then(({data}) => {
      setDirecciones(data);
    }).catch((error) => {
      toast.error("Ha habido un error al consultar sus direcciones")
    }).finally(() => {
        cargaEstados();
    });
  }

  const cargarOperadoresContactados = () => {
    const usr_id = localStorage.getItem("usr_id");

    merent_instance().get(`/api/intencioncontratacion/cliente/${usr_id}`).then(({data}) => {
      setOperadoresContactados(data.map((op) => ({...op, calificacion: op.calificacion === 0 ?  _(<button className={"text-primary-500 underline cursor-pointer"} onClick={() => showCalificarOperador(op)}>Calificar</button>) : op.calificacion})));
    }).catch((error) => {
      toast.error("Ha habido un error al consultar sus direcciones")
    })
  }

  const cargarMotivosTicket = () => {
    merent_instance().get(`/api/ticketsoporte/motivos`).then(({data}) => {
      if(data !== null && data.length > 0){
        setMotivosTicket(data.map((mot) => {
          return {value: mot.id, label: mot.nombre, descripcion: mot.descripcion};
        }));
      }
    }).catch((error) => {
      toast.error("Ha habido un error al consultar los motivos de ticket")
    })
  }

  const cargarTicketsSoporte = () => {
    const usr_id = localStorage.getItem("usr_id");
    merent_instance().get(`/api/ticketsoporte/cliente/${usr_id}`).then(({data}) => {
      setTicketsSoporte(data.map((tic) => ({...tic,
        rentaRef: _(<button className={"text-primary-500 underline cursor-pointer"} onClick={() => showDetails({id: tic.rentaRef})}># {tic.rentaRef}</button>)}))
      );
    }).catch((error) => {
      toast.error("Ha habido un error al consultar los tickets")
    })
  }

  const cargarRentas = () => {
    const usr_id = localStorage.getItem("usr_id");
    merent_instance().get(`/api/rentas/cliente/${usr_id}/perfil`)
    .then(({data}) => {
      setRentas(data.map((op) => ({...op,
        numeroRenta: _(<button className={"text-primary-500 underline cursor-pointer"} onClick={() => showDetails(op)}>{op.numeroRenta}</button>),
        calificacion: op.calificacion === 0 & op.estadoRentaRef != 8 ?  _(<button className={"text-primary-500 underline cursor-pointer"} onClick={() => showCalificarRenta(op)}>Calificar</button>) : op.calificacion,
        extenderRenta: op.fechaRentaTerminada < formatDate(new Date()) ? '' : _(<button className={"text-primary-500 underline cursor-pointer"} onClick={() => showExtenderRenta(op)}>Extender Renta</button>)})));
    })
    .catch((error) => {
      console.log(error);
      toast.error("Error al consultar rentas");
    })
  }

  const resetFields = () => {
    setDireccionFields({
      id: 0,
      nombre: "",
      direccionCompleta: '',
      calle: '',
      numeroInterior: '',
      numeroExterior: '',
      colonia: '',
      ciudadRef: 0,
      estadoRef: 0,
      codigoPostal: 0,
      latitud: 0,
      longitud: 0,
      isPrincipal: true
    });
  }
  
  const saveDireccion = () => {
      if(direccionFields.id === 0){
        //Nueva Direccion
        const cliente_id = localStorage.getItem("usr_id");
        if(!cliente_id){
          toast.error("Su sesión ha expirado, favor de volver a iniciar");
          navigation("/login");
          return;
        }
        const new_direccion = {
          clienteRef: cliente_id,
          isPrincipal: direccionFields.isPrincipal,
          direccion: {
            ...direccionFields
          }
        }

        merent_instance().post("/api/direcciones", new_direccion).then(({data}) => {
          toast.success(data);
          resetFields();
          setShowDireccion(false);
        }).catch(error => {
          console.log(error);
          toast.error("Ha habido un error al guardar su nueva dirección, favor de intentarlo de nuevo");
        }).finally(() => {
          cargarDireccionesCliente();
        })
      } 
      else {
        //Actualizar Dirección
        const cliente_id = localStorage.getItem("usr_id");
        if(!cliente_id){
          toast.error("Su sesión ha expirado, favor de volver a iniciar");
          navigation("/login");
          return;
        }
        const update_direccion = {
          id: direccionFields.id,

          direccion: {
            ...direccionFields
          }
        }
        console.log(update_direccion)
        merent_instance().patch(`/api/direcciones/${direccionFields.id}`, update_direccion).then(({data}) => {
          toast.success(data);
          resetFields();
          setShowDireccion(false);
        }).catch(error => {
          console.log(error);
          toast.error("Ha habido un error al editar la dirección, favor de intentarlo de nuevo");
        }).finally(() => {
          cargarDireccionesCliente();
        })
      }
  };

  const editarDireccion = (direccion) => {
    const {direccion: obj_direccion} = direccion;
    setDireccionFields({
      id: direccion.id,
      nombre: obj_direccion.nombre,
      direccionCompleta: obj_direccion.direccionCompleta,
      calle: obj_direccion.calle,
      numeroInterior: obj_direccion.numeroInterior,
      numeroExterior: obj_direccion.numeroExterior,
      colonia: obj_direccion.colonia,
      ciudadRef: obj_direccion.ciudadRef,
      estadoRef: obj_direccion.ciudad.estado.id,
      codigoPostal: obj_direccion.codigoPostal,
      isPrincipal: direccion.isPrincipal,
      latitud: 0,
      longitud: 0
    });
    
    if(obj_direccion.ciudad.estado.id != 0){
      cargaCiudades(obj_direccion.ciudad.estado.id);
    }
    setShowDireccion(true); 
  }

  const borrarDireccion = (direccion) => {
    setDireccionFields(direccion); 
    setShowDeleteDireccion(true); 
  }

  const confirmDeleteDireccion = () => {
    toast.success("Dirección eliminada");
    setShowDeleteDireccion(false)
  }

  const confirmCalificarOperador = () => {
    const new_calificacion = {
      id: operadorActual.id,
      calificacion: calificacionOperador
    }
    merent_instance().put("/api/intencioncontratacion", new_calificacion).then(({data}) => {
      console.log(data);
      toast.success("Operador calificado");
      cargarOperadoresContactados();
      setCalificarOperador(false)
    }).catch((e) => {
      console.log(e);
      toast.error("Hubo un error al calificar al operador, intente nuevamente más tarde");
    });
  }

  const showDetails = (renta) => {
    const usr_id = localStorage.getItem("usr_id");
    merent_instance().get(`/api/rentas/cliente/${usr_id}`)
    .then(({data}) => {
      if(data !== null && data.length > 0){
        var dataRenta = data.filter((x) => {
            if(x.id == renta.id){
                return true;
            }
            else{
                return false;
            }
        });
        if(dataRenta !== null){
            console.log(dataRenta[0])
            setRentaActual(dataRenta[0]);
            setShowDetail(true);
        }
        else{
          toast.error("Error al consultar información de la renta");
        }
      }
      else{
        toast.error("Error al consultar información de la renta");
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Error al consultar información de la renta");
    })
  }

  const showCalificarOperador = (op) => {
    setOperadorActual(op);
    setCalificarOperador(true);
  }

  const showCalificarRenta = (op) => {
    setRentaActual(op);
    setCalificarRenta(true);
  }

  const confirmCalificarRenta = (op) => {
    merent_instance().patch(`/api/rentas/${rentaActual.id}/calificacion/${calificacionRenta}?comentarios=${comentariosRenta}`).then(({data}) => {
      toast.success("Renta calificada");
      cargarRentas();
      setCalificarRenta(false);
      setComentariosRenta("");
    }).catch((e) => {
      console.log(e);
      toast.error("Hubo un error al calificar la renta, intente nuevamente más tarde");
    });
  }

  const showExtenderRenta = (op) => {
    console.log(op.fechaRentaTerminada);
    setRentaActual(op);
    setCotizacion({disponible: false, precio: 0, validado: false});
    setNuevaFecha(new Date(op.fechaRentaTerminada));
    setNuevaFechaFormat("");
    setCantidadDias(0);
    setExtenderRenta(true);
  }

  const solicitarCotizacion = () => {
    if(nuevaFecha === undefined || nuevaFecha === null || nuevaFecha === ""){
      toast.error("Favor de especificar la nueva fecha en que entregaría el equipo");
      return;
    }

    setLoading(true);
    merent_instance().get(`/api/rentas/${rentaActual.id}/extender/validacion?fecha=${nuevaFecha}`).then(({data}) => {
      setLoading(false);
      console.log(data);
      setCotizacion({...data, validado: true});
    }).catch((e) => {
      setLoading(false);
      console.log(e);
      toast.error("Hubo un error al obtener la cotización, intente nuevamente más tarde");
    });
  }

  const confirmExtenderRenta = () => {
    if(nuevaFecha === undefined || nuevaFecha === null || nuevaFecha === ""){
      toast.error("Favor de especificar la nueva fecha en que entregaría el equipo");
      return;
    }
    merent_instance().patch(`/api/rentas/${rentaActual.id}/extender?fecha=${nuevaFecha}`).then(({data}) => {
      toast.success("Solicitud de extensión de Renta enviada");
      cargarRentas();
      setCotizacion({disponible: false, precio: 0, validado: false});
      setNuevaFecha("");
      setNuevaFechaFormat("");
      setCantidadDias(0);
      setExtenderRenta(false);
    }).catch((e) => {
      console.log(e);
      toast.error("Hubo un error al solicitar la extensión de renta, intente nuevamente más tarde");
    });
  }

  const modifiarEstado = (estado) => {
    setDireccionFields(estado);
    if(estado.estadoRef != 0){
      cargaCiudades(estado.estadoRef);
    }
  }

  const cargaEstados = () => {
    merent_instance().get("api/direcciones/estados/false").then(({data}) => {
        const new_estados = data.map(estado => {
            return {
                id: estado.id,
                value: estado.id,
                label: estado.nombre
            }
        });
        setEstados(new_estados);

      }).catch((error) => {
        console.log(error);
        toast.error("Hubo un error al consultar información");
      })
  }

  const cargaCiudades = (estadoRef) => {
    merent_instance().get(`api/direcciones/ciudades/${estadoRef}/false`).then(({data}) => {
        const new_ciudades = data.map(ciudad => {
            return {
                id: ciudad.id,
                value: ciudad.id,
                label: ciudad.nombre
            }
        });
        setCiudades(new_ciudades);
      }).catch((error) => {
        console.log(error);
        toast.error("Hubo un error al consultar información");
      })
  }

  const fieldError = (field) => {
    if(field === undefined || field === null || field === '' || field === 0){
        return true;
    }
    else{
        return false;
    }
  }

  const confirmSolicitarSoporte = (op) => {
    var ticket_data = {
      rentaRef: rentaActual.id,
      motivoTicketRef: ticketFields.motivoTicketRef,
      comentarios: ticketFields.comentarios
    }
    console.log(ticket_data);
    var error = 0;
    for(var key in ticket_data) {
        var value = ticket_data[key];
        if(fieldError(value)){
            error++;
            toast.error("Todos los campos son obligatorios");
            return;
        }
    }
    if(error > 0){
        return;
    }

    merent_instance().post(`/api/ticketsoporte`, ticket_data).then(({data}) => {
      toast.success(data);
      var text = `*${data}*. Comentarios: ${ticketFields.comentarios}`;
      window.open('https://wa.me/8116605169?text='+text, '_blank', 'noreferrer');
      setSolicitarSoporte(false);
      setTicketFields({rentaRef: 0,
        motivoTicketRef: 0,
        comentarios: ''});
    }).catch((e) => {
      console.log(e);
      toast.error("Hubo un error al calificar la renta, intente nuevamente más tarde");
    });
  }

  const handleDateChance = (date) => {
    setNuevaFecha(date); 
    setNuevaFechaFormat(formatDate(date)); 
    setCotizacion({disponible: false, precio: 0, validado: false});
    const date1 = new Date(rentaActual.fechaRentaTerminada);
    const diffTime = Math.abs(date - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    setCantidadDias(diffDays);
  }

  function formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
  }

  return (
    <Layout>
      <Container className="flex justify-center items-center"> 
          <div className="px-5 pt-5 rounded-lg bg-white shadow-lg w-full md:w-4/5 lg:w-4/5 xlg:w-4/5 ">
            <div className="flex font-semibold text-4xl m-4 text-primary-300 align-text-bottom">
              <Image
                src={avatar}
                alt="MERENT user"
                width={"100px"}
                height={'100px'}
                className={"rounded-full border-4 border-black"}

                style={{ width: "100px", borderRadius: "50%", borderColor: "#FEA381", borderWidth: '5px'}}
              />
              <h2 className="mt-12 ml-5">Mi perfil</h2>
            </div>
            <div className="p-0 my-7 mx-5">
                <label className='bg-primary-300 text-white text-sm px-3 py-2'>Información de mi cuenta</label>
                <hr className='border-primary-300 border-2 my-1'/>
                <div className="mt-3">
                  <Form 
                    formFields={form_fields_perfil} 
                    formState={perfilFields} 
                    formModifyState={setPerfilFields}
                    formSave={savePerfil}
                    buttonText="Actualizar los datos de mi cuenta"
                  />
                </div>
            </div>
            <div className="p-0 my-7 mx-5">
                <label className='bg-primary-300 text-white text-sm px-3 py-2'>Mis direcciones</label>
                <hr className='border-primary-300 border-2 my-1'/>
                <div className="flex flex-col align-middle justify-center p-5">
                  {
                    direcciones.length === 0 ? 
                      <h6 className="text-center font-light">No hay direcciones registradas</h6>
                      :
                      direcciones.map((dir) => {
                        return (
                          <Address 
                            key={dir.id}
                            data={dir.direccion} 
                            esPrincipal={dir.isPrincipal}
                            editFn={ () => editarDireccion(dir) } 
                            deleteFn={ () => borrarDireccion(dir) }
                          />
                        )
                      })
                  }
                  <br/>
                  <button className="bg-secondary-100 hover:bg-secondary-200 text-sm text-white p-2 rounded-sm" 
                    type="button" 
                    onClick={() => { resetFields(); setShowDireccion(true)}}>
                      Agregar nueva dirección
                  </button>
                </div>
            </div>

            <div className="p-0 my-7 mx-1">
                <label className='bg-primary-300 text-white text-sm px-3 py-2'>Mis rentas</label>
                <hr className='border-primary-300 border-2 my-1'/>
                <Datagrid
                  header={grid_columns_rentas}
                  data={rentas}
                />
            </div>

            <div className="p-0 my-7 mx-1">
                <label className='bg-primary-300 text-white text-sm px-3 py-2'>Mis tickets de soporte</label>
                <hr className='border-primary-300 border-2 my-1'/>
                <Datagrid
                  header={grid_columns_tickets}
                  data={ticketsSoporte}
                />
            </div>

            <div className="p-0 my-7 mt-10 mx-1">
                <label className='bg-primary-300 text-white text-sm px-3 py-2'>Operadores contactados</label>
                <hr className='border-primary-300 border-2 my-1'/>
                <Datagrid
                  header={grid_columns_operadores}
                  data={operadoresContactados}
                />
            </div>

            <Modal className="max-w-6xl" title={direccionFields.id === 0 ? "Nueva Dirección" : "Actualizar Dirección"} show={showDireccion} setShow={setShowDireccion}>
                <div className="mt-3">
                  <Form 
                    formFields={form_fields_perfil_direcciones(estados, ciudades)} 
                    formState={direccionFields} 
                    formModifyState={modifiarEstado}
                    formSave={saveDireccion}
                    buttonText={direccionFields.id === 0 ? "Guardar" : "Actualizar"}
                  />
                </div>
            </Modal>
            <Modal className="max-w-2xl" title={`Eliminar dirección`} show={showDeleteDireccion} setShow={setShowDeleteDireccion}>
                <header className="w-full">
                    <p>¿Seguro que deseas eliminar la dirección con alias "{direccionFields.alias}"?</p>
                </header>
                <section className="grid grid-cols-2 gap-x-8 gap-y-1">
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1   bg-secondary-200 hover:bg-secondary-300" type="button" text="Cancelar" clickFn={() => {setShowDeleteDireccion(false)}}/>
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 bg-primary-300 hover:bg-primary-400" type="button" text="Desactivar" clickFn={confirmDeleteDireccion}/>
                </section>
            </Modal>
            <Modal className="max-w-xl" title={`Detalle de Renta # ${rentaActual.id !== undefined && rentaActual.id !== null ? rentaActual.id : ''}`} show={showDetail} setShow={setShowDetail}>
              <div className="w-full h-full">
              {
                rentaActual !== undefined && rentaActual !== null && rentaActual.id !== undefined && rentaActual.id !== null && showDetail ? 
                  <>
                    <div className="w-full text-center">
                      <div className="w-full text-left text-base px-2">
                          <h5 className='text-primary-400 font-bold text-base'>
                              Empresa: <span className="text-secondary-300 text-base">{rentaActual.rentero.nombreEmpresa}</span>
                          </h5>
                          <div className="flex flex-row justify-start w-full px-2">
                              <FaEnvelope className="text-base text-primary-400"/>
                              <label className='text-secondary-300 text-base ml-2'>{rentaActual.rentero.usuario === null ? '[email Rentrero]' : rentaActual.rentero.usuario.email}</label>
                          </div>
                          <div className="flex flex-row justify-start w-full px-2">
                              <FaMapMarkedAlt className="text-base text-primary-400"/>
                              <label className='text-secondary-300 text-base ml-2'>{rentaActual.rentero.direccion === null ? '[direccion Rentrero]' : rentaActual.rentero.direccion.direccionCompleta}</label>
                          </div>
                          <div className="flex flex-row justify-start w-full px-2">
                              <FaPhone className="text-base text-primary-400"/>
                              <label className='text-secondary-300 text-base ml-2'>{rentaActual.rentero.usuario === null ? '[telefono Rentrero]' : rentaActual.rentero.usuario.phoneNumber}</label>
                          </div>
                      </div>
                    
                      <div className="w-full text-left text-base px-2 my-4">
                        <h5 className='text-primary-400 font-bold text-base'>
                            Equipos
                        </h5>
                        {
                            rentaActual && rentaActual.equipoRenta !== undefined && rentaActual.equipoRenta !== null && rentaActual.equipoRenta.length > 0 ?
                              rentaActual.equipoRenta.map((equipo, idx) => {
                                return <ShoppingCartElement 
                                          key={equipo.id} 
                                          title={equipo.equipo.nombre} 
                                          description={`${equipo.equipo.descripcion}`} 
                                          precio={equipo.precioTotal} 
                                          link={`/${equipo.equipo.nombre}`}
                                          // image={equipo.equipo.imagenEquipos[0]}
                                          index={idx}
                                        /> 
                                })
                            :
                            'No hay elementos que mostrar'
                        }
                      </div>                
                    </div>
                    <div className="flex w-full">
                        <div className="w-full text-left text-base px-2">
                            <h5 className='text-primary-400 font-bold text-base'>
                                Fechas de renta:
                            </h5>
                            <div className="flex flex-row justify-start w-full px-2">
                                <FaRegCalendarAlt className="text-base text-primary-400"/>
                                <label className='text-primary-500 text-base ml-2'>Inicio: </label>
                                <label className='text-secondary-300 text-base ml-2'>{rentaActual ? rentaActual.fechaInicio.substring(0,10) : ""} </label>
                            </div>
                            <div className="flex flex-row justify-start w-full px-2">
                                <FaRegCalendarAlt className="text-base text-primary-400"/>
                                <label className='text-primary-500 text-base ml-2 mr-4'>Fin: </label>
                                <label className='text-secondary-300 text-base ml-2'>{rentaActual ? rentaActual.fechaFin.substring(0,10) : ""}</label>
                            </div>
                        </div>
                        {/* {rentaActual ? renderCalendar() : <></>} */}
                    </div>
                    <div className="flex flex-col items-end mt-2 mx-2">
                        <h5 className="text-base font-light text-secondary-400">Flete: ${rentaActual ? rentaActual.precioFlete : "0.00"} MXN</h5>
                        <h3 className="text-lg font-bold text-primary-400">Total: ${rentaActual ? rentaActual.precioTotal : "0.00"} MXN</h3>
                    </div>
                    <div className="w-full mb-2">
                      <button className="flex flex-row justify-center items-center border-2 w-full rounded-lg border-white bg-green-600 p-2 hover:bg-green-400 text-center text-white font-bold" type="button" onClick={() => {setSolicitarSoporte(true)}}><FaWhatsapp className="text-lg text-white mr-1"/> Solicitar Soporte</button>
                    </div>
                </>
                :
                ''
                }
                </div>
            </Modal>

            <Modal className="max-w-xl" title={`Solicitar Soporte`} show={solicitarSoporte} setShow={setSolicitarSoporte}>
              {
                rentaActual !== undefined && rentaActual !== null && rentaActual.id !== undefined && rentaActual.id !== null ? 
                  <div className="mt-3 font-semibold">
                    Solicitar soporte para la renta <b># {rentaActual.id}</b> de la empresa <b>{rentaActual.rentero.nombreEmpresa}</b>:
                    <div className="text-xl text-secondary-500 mt-2 pr-4">
                      <span className="text-sm text-secondary-400 font-light pl-1">Motivo del soporte:</span>
                      <BasicInput className="mt-0 border-2 border-primary-300 font-normal" type='select' defaultValue={ticketFields.motivoTicketRef !== null ? ticketFields.motivoTicketRef : 0} setValue={(e) => {setTicketFields({...ticketFields, motivoTicketRef: e.target.value})}} options={motivosTicket} />
                    </div>
                    <div className="text-xl text-secondary-500 mt-4 pr-4">
                        <span className="text-sm text-secondary-400 font-light pl-1">Cuéntanos más sobre el soporte que requieres:</span>
                        <BasicInput
                          type="text" 
                          placeholder="Ingresa aquí tu mensaje" 
                          defaultValue={ticketFields.comentarios !== null ? ticketFields.comentarios : ''}
                          setValue={(e) => {setTicketFields({...ticketFields, comentarios: e.target.value})}}
                          className="w-full font-normal"
                        />
                    </div>
                    <section className="grid grid-cols-2 gap-x-2 gap-y-1 mt-4">
                        <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1   bg-secondary-200 hover:bg-secondary-300" type="button" text="Cancelar" clickFn={() => {setSolicitarSoporte(false);  setTicketFields({rentaRef: 0,motivoTicketRef: 0,comentarios: ''});}}/>
                        <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 bg-primary-300 hover:bg-primary-400" type="button" text="Solicitar Soporte vía Whatsapp" clickFn={confirmSolicitarSoporte}/>
                    </section>
                  </div>
                :
                ''
              }
            </Modal>

            <Modal className="max-w-2xl" title={`Calificar Operador`} show={calificarOperador} setShow={setCalificarOperador}>
                <div className="mt-3">
                  Califica al operador {operadorActual.nombreOperador} por sus servicios brindados en la categoría de {operadorActual.categoria}:
                  <div className="text-xl text-secondary-500 mt-0 pr-4">
                      <Rate defaultValue={calificacionOperador} classNames="justify-center" onSelectRate={(rate) => setCalificacionOperador(rate)}/>
                  </div>
                </div>
                <section className="grid grid-cols-2 gap-x-8 gap-y-1">
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1   bg-secondary-200 hover:bg-secondary-300" type="button" text="Cancelar" clickFn={() => {setCalificarOperador(false)}}/>
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 bg-primary-300 hover:bg-primary-400" type="button" text="Guardar" clickFn={confirmCalificarOperador}/>
                </section>
            </Modal>

            <Modal className="max-w-2xl" title={`Calificar Servicio de renta`} show={calificarRenta} setShow={setCalificarRenta}>
                <div className="mt-3">
                  Califica la renta numero <b>{rentaActual.id}</b> de <b>{rentaActual.rentero}</b> por sus servicios prestados en la fecha <b>{rentaActual.rangoFechas}</b>:
                  <div className="text-xl text-secondary-500 mt-0 pr-4">
                      <Rate defaultValue={calificacionRenta} classNames="justify-center" onSelectRate={(rate) => setCalificacionRenta(rate)}/>
                  </div>
                  <div className="text-xl text-secondary-500 mt-0 pr-4">
                        <span className="text-sm text-secondary-400 font-bold">Ingresa aquí tu experiencia con esta renta</span>
                        <BasicInput
                                type="text" 
                                placeholder="Ingresa aquí tus comentarios" 
                                defaultValue={comentariosRenta}
                                setValue={(e) => {setComentariosRenta(e.target.value)}}
                                className="w-full "
                            />
                  </div>
                </div>
                <section className="grid grid-cols-2 gap-x-8 gap-y-1">
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1   bg-secondary-200 hover:bg-secondary-300" type="button" text="Cancelar" clickFn={() => {setCalificarRenta(false)}}/>
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 bg-primary-300 hover:bg-primary-400" type="button" text="Guardar" clickFn={confirmCalificarRenta}/>
                </section>
            </Modal>

            <Modal className="max-w-2xl" title={`Solicitar Extensión de renta`} show={extenderRenta} setShow={setExtenderRenta}>
              {
                loading ?
                <h5 className="text-primary-400 text-center">Validando disponibilidad y precio...</h5>
                :
                <>
                  <div className="mt-3">
                    Seleccione la nueva fecha a la que desea extender la renta <b className="text-primary-400"># {rentaActual.id}</b> de la empresa <b className="text-primary-400">{rentaActual.rentero}</b>
                    <br/> 
                    <p>Rango de fechas original: <b className="text-primary-400">{rentaActual.rangoFechas}</b></p>
                    <div className="mt-2 grid grid-cols-12 gap-x-2 gap-y-2">
                        <div className="col-span-6 xs:col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xlg:col-span-6 flex justify-center ">
                          <Calendar
                            date={nuevaFecha}
                            onChange={date => { handleDateChance(date) }}
                            locale={es}
                            color="#FE6830"
                            minDate={new Date(rentaActual.fechaRentaTerminada)}
                            weekStartsOn={0}
                          />
                        </div>
                        <div className="text-base col-span-6 xs:col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xlg:col-span-6 px-2 pt-2">
                            <label className='text-primary-300 font-semibold text-sm'>Selección </label> 
                            <label className='text-primary-300 font-semibold text-sm'>({cantidadDias} Días)</label>
                            <br/>
                            <label className='text-secondary-300 font-semibold text-lg'>{rentaActual.fechaRentaTerminada} - {nuevaFechaFormat}</label>
                            <br/>
                            <br/>
                            <label className='text-primary-300 font-semibold text-sm'>Tipo de costo </label>
                            <br/>
                            <label className='text-secondary-300 font-semibold text-lg'>Diario</label>
                            <br/>
                            <br/>
                            {
                              cotizacion.validado && !cotizacion.disponible ?
                                <>
                                  <label className='text-red-600 font-semibold text-base'>El equipo no se encuentra disponible para extender la renta a este día </label><br/>
                                  <label className='text-secondary-300 text-sm'>Intenta con una fecha diferente</label>
                                </>
                              :
                              <>
                                <label className='text-primary-300 font-semibold text-sm'>Subtotal </label>
                                <br/>
                                <label className='text-secondary-300 font-semibold text-lg'>{cotizacion.precio === 0 ? 'Por definir' : '$ ' + cotizacion.precio + 'MXN'} </label>
                                </>
                            }

                            <br/>
                            <br/>
                            <label className='text-xs'>* El costo es adicional a lo ya pagado por la renta </label>
                        </div>
                    </div>
                  </div>
                  {
                    cotizacion.validado && cotizacion.disponible ?
                    <>
                      <section className="grid grid-cols-1 gap-x-8 gap-y-1">
                          <BasicButton className="col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 bg-primary-300 hover:bg-primary-400" type="button" text="Solicitar extensión de la Renta" clickFn={confirmExtenderRenta}/>
                      </section>
                    </>
                    :
                    <section className="grid grid-cols-2 gap-x-8 gap-y-1">
                        <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 bg-secondary-200 hover:bg-secondary-300" type="button" text="Cancelar" clickFn={() => {setExtenderRenta(false)}}/>
                        <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 bg-primary-300 hover:bg-primary-400" type="button" text="Validar disponibilidad y precio" clickFn={solicitarCotizacion}/>
                    </section>
                  }
                </>
              }
            </Modal>
           
          </div>   
      </Container>
    </Layout>
)
}

export default Perfil;
