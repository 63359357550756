import React, { Fragment }from "react";
import ThumbnailsOperadores from "./ThumbnailsOperadores";

const ThumbnailsGroupOperadores = ({operadores, groupName, description}) => {
  return(
        <Fragment>
            {
                operadores !== undefined && operadores !== null && operadores.length > 0 ?
                    <Fragment>
                        <div className="p-0 mt-10 mb-5">
                            <label className='bg-primary-500 text-white text-sm px-3 py-2'>{groupName}</label>
                            <hr className='border-primary-500 border-2 mr-10 my-1'/>
                            {
                                description !== null && description !== '' ? 
                                    <p className="mx-7 my-3 text-center">{description}</p>
                                :
                                ''
                            }
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-3 gap-y-3 mx-8 px-5">
                            {
                                operadores.map((operador) => {
                                    return (
                                    <ThumbnailsOperadores
                                        key={operador.id} 
                                        title={`${operador.nombre} ${operador.apellidos}`} 
                                        description={operador.descripcion} 
                                        categoria={operador.categoria} 
                                        location={`${operador.ciudad}, ${operador.estado}`} 
                                        preview_info={<strong key={operador.id}>Precio Diario $ {operador.precioDia}</strong>} 
                                        link={`/operador/${operador.id}/`}
                                        button_text="Ver información de contacto"
                                        calificacion={operador.calificacionGeneral}
                                        image={operador.imagenPerfil ? operador.imagenPerfil : null}
                                        />
                                    )
                                })
                            }
                        </div>
                    </Fragment>
                :
                ''
            }
        </Fragment>
    )
}
    
export default ThumbnailsGroupOperadores;