import React, {useState, useEffect} from "react";
import Layout from "../components/UI/Layout";
import Container from "../components/UI/Container";
import Busqueda from "../components/Busqueda";
import 'rsuite/dist/rsuite.min.css';
import { merent_instance } from "../helpers/axios_helper";
import toast from "react-hot-toast";

import { Link } from "react-router-dom";
import Image from "../components/UI/LazyLoadImage";
import appstore from "../images/appstore.png";
import playstore from "../images/playstore_1.png";

const IndexPage = ({data}) => {
  const [equipos, setEquipos] = useState([]);

  useEffect(() => {
    merent_instance().get("api/equipos").then(({data}) => {
      setEquipos(data);
    }).catch((error) => {
      toast.error("Error al cargar equipos, intenta nuevamente más tarde");
    });
  }, []);

  return (
    <Layout>
      <Container className='p-0'>
        <div className="grid grid-cols-2 xs:grid-cols-1">
          <div className="m-2 p-1 text-lg rounded-2xl bg-white shadow-md shadow-primary-100 border-2 border-primary-200">
            <p className="m-5 text-sm text-center">
              <label className="font-semibold text-base my-3 text-primary-500"> Renta Segura</label> <br/>
              Tratas directamente con Merent quien administrará la renta desde la contrataciòn hasta su termino. Un aviso le llegara a nuestro equipo, quien se pondra en contacto contigo para llevar acabo el proceso
            </p>
          </div>          
          <div className="m-2 p-1 text-lg rounded-2xl bg-white shadow-md shadow-primary-100 border-2 border-primary-200">
            <p className="m-5 text-sm text-center">
              <label className="font-semibold text-base my-3 text-primary-500"> Renta Abierta</label> <br/>
              Te proporcionamos los datos de contacto de un integrante de nuestra comunidad que tenga el equipo de tu interes (las condiciones de la renta las pactaran directamente.</p>
          </div>
        </div>
        <div className="flex w-full justify-around">
          <Link to="/">
            <Image
              src={appstore}
              alt="MERENT Clientes appstore"
              width={150}
            />
          </Link>
          <Link to="/">
            <Image
                src={playstore}
                alt="MERENT Clientes playstore"
                width={150}
            />
          </Link>
        </div>
        <Busqueda
          equipos={equipos}
        /> 
        <br/>          
      </Container>
    </Layout>
  )
}

export default IndexPage;