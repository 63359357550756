import React, {useState, useEffect} from "react";
import Layout from "../Layout";
import Container from "../Container";
import Carousel from '../Carousel';
import BasicButton from '../Buttons/BasicButton';
import Compact from "../Compact";
import ThumbnailsGroup from "../Thumbnails/ThumbnailsGroup";
import Rate from "../Rate";
import Modal from '../Modal';
import DatePicker from "../Inputs/DatePicker";
import Comment from "../Comment";
import { format, addDays, differenceInDays } from 'date-fns';
import toast from 'react-hot-toast';
import FavoriteButton from '../Buttons/FavoriteButton';
import { 
    FaEnvelope, 
    FaMapMarkerAlt, 
    FaPhone, 
    FaCheckCircle, 
    FaFacebook,
    FaLinkedin,
    FaTwitterSquare,
    FaWhatsappSquare,
} from "react-icons/fa";
import { merent_instance } from "../../../helpers/axios_helper";
import { Link, useLoaderData } from "react-router-dom";
import { useParams } from 'react-router-dom';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
  } from "react-share";
import { obras_participa } from "../../../helpers/dummy_data";

const EquipoTemplate = () => {
    const {id_equipo, id_rentero} = useParams();
    const [informacionEquipo, setInformacionEquipo] = useState({
        id: 1,
        precioDia: "0.0",
        precioSemana: "0.0",
        precioQuincena: "0.0",
        precioMes: "0.0",
        esquema: 1,
        nombreEsquema: "Renta Segura",
        nombreEquipo: "Hola",
        imagenEquipos: [],
        nombreEmpresa: "Rentas",
        ubicacionEmpresa: "Direccion",
        rentero: {
            calificacionGeneral: 5,
            usuario: {
                email:"",
                phoneNumber: "",
            },
            direccion:{
                direccionCompleta:"",
            }
        },
        descripcion: "Descripcion",
        categoriaEquipo: {nombre: "categoria"},
        modelo: "Modelo",
        horometro: "Horometro",
        serviciosEquipos: [],
        aditamentosEquipos: [],
        TodosEquipos: [],
    });
    const [otrosEquipos, setOtrosEquipos] = useState([]);
    const [diasBloqueados, setDiasBloqueados] = useState([]);
    const [comentarios, setComentarios] = useState([]);

    const [showDatosContacto, setShowDatosContacto] = useState(false);
    const [showRentar, setShowRentar] = useState(false);
    const [showSiguientePaso, setShowSiguientePaso] = useState(false);
    const [favorito, setFavorito] = useState(false);
    const [obras, setObras] = useState([]);
    const [renta, setRenta] = useState({
        id: 0,
        equipo_id: id_equipo,
        datesRange: {
            startDate: addDays(new Date(),1),
            endDate: addDays(new Date(),1),
            key: 'selection',
        },
        fechaInicio: '',
        fechaFin: '',
        totalDias: '',
        tipoCosto: 'Diario',
        costoDia: informacionEquipo.precioDia,
        subtotal: 0.0
    });

    useEffect(() => {
        cargarEquipo();
        cargarOtrosEquipos();
        cargaDiasBloqueados();
        cargaComentarios();
        cargaObrasEquipo();
        cargarFavoritos();
    }, []);

    const cargarEquipo = () => {
        merent_instance().get(`/api/equipos/${id_equipo}`).then(({data}) => {
            setInformacionEquipo(data)
        }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al obtener los días bloqueados del equipo");
        })
    }

    const cargarOtrosEquipos = () => {
        merent_instance().get("api/equipos").then(({data}) => {
            setOtrosEquipos(data.filter(x => x.id != id_equipo && x.rentero.id == id_rentero));
        }).catch((error) => {
        toast.error("Error al cargar equipos, intenta nuevamente más tarde");
        });
    }

    const cargaDiasBloqueados = () => {
        merent_instance().get(`/api/equipos/${id_equipo}/indisponibilidad`).then(({data}) => {
            setDiasBloqueados(data);
        }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al obtener los días bloqueados del equipo");
        })
    }

    const cargaComentarios = () => {
        merent_instance().get(`/api/renteros/${id_rentero}/comentarios`).then(({data}) => {
            setComentarios(data);
        }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al obtener los días bloqueados del equipo");
        })
    }

    const cargaObrasEquipo = () => {
        merent_instance().get(`/api/obra/equipo/${id_equipo}`).then(({data}) => {
            setObras(data);
        }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al obtener los días bloqueados del equipo");
        })
    }

    const cargarFavoritos = () => {
        const usr_id = localStorage.getItem("usr_id");
        if(usr_id === undefined || usr_id === null || usr_id === 0) {
            return;
        }

        merent_instance().get(`api/clientes/${usr_id}/favoritos/`)
        .then(({data}) => {
            const data_filtered = data.filter(x => x.id == id_equipo);
            if(data_filtered.length > 0)
                setFavorito(true);
        })
        .catch((error) => {
            console.log("Error al cargar favoritos");
        })
    }
    
    const handleDatesRangesSelection = (ranges) =>{
        var total_dias = differenceInDays(ranges.endDate, ranges.startDate) + 1;
        var tipo_costo = 'Diario';
        var costo_dia = parseFloat(informacionEquipo.precioDia);
        switch (true) {
            case total_dias >= 30:
                tipo_costo = 'Mensual';
                costo_dia = parseFloat(informacionEquipo.precioMes) / 30;
                break;

            case total_dias >= 15:
                tipo_costo = 'Quincenal';
                costo_dia = parseFloat(informacionEquipo.precioQuincena) / 15;
                break;

            case total_dias >= 7:
                tipo_costo = 'Semanal';
                costo_dia = parseFloat(informacionEquipo.precioSemana) / 7;
                break;
            
            default:
                break;
        }
        setRenta({...renta, 
            datesRange: ranges, 
            fechaInicio: format(ranges.startDate, 'yyyy-MM-dd'), 
            fechaFin: format(ranges.endDate, 'yyyy-MM-dd'),
            totalDias: total_dias,
            tipoCosto: tipo_costo,
            costoDia: costo_dia.toFixed(2),
            subtotal: (parseFloat(costo_dia) * parseInt(total_dias)).toFixed(2)
        });
    };
    
    const handleRenta = () => {
        if(renta.totalDias === null || renta.totalDias === 0){
            toast.error("Se debe seleccionar al menos 1 día");
        }
        else{
            const renta_final = {...renta, ...informacionEquipo}
            const carrito = localStorage.getItem("carrito");
            if(carrito){
                const new_carrito = [...JSON.parse(carrito), renta_final];
                localStorage.setItem("carrito", JSON.stringify(new_carrito));
            } else {
                const new_carrito = [renta_final];
                localStorage.setItem("carrito", JSON.stringify(new_carrito));
            }
            //toast.success("Renta agregada al carrito");
            setShowRentar(false);
            setShowSiguientePaso(true);
        }
    }

    const handleFavorito = () => {
        const usr_id = localStorage.getItem("usr_id");
        if(usr_id === undefined || usr_id === null || usr_id === 0) {
            toast.warn("Debes iniciar sesión")
            return;
        }
        const body_data = {
            clienteRef: usr_id,
            equipoRef: id_equipo
        };

        if(favorito) {
            merent_instance().delete(`api/clientes/${body_data.clienteRef}/favoritos/${body_data.equipoRef}`)
            .then(({data}) => {
                toast.success(data);
                setFavorito(!favorito)
            })
            .catch(({error}) => {
                console.log(error);
                toast.error("No se pudo eliminar de favoritos, intenta nuvamente");
            })
        } else {
            merent_instance().post("api/clientes/favoritos",body_data)
            .then(({data}) => {
                toast.success(data);
                setFavorito(!favorito)
            })
            .catch(({error}) => {
                console.log(error);
                toast.error("No se pudo agregar a favoritos, intenta nuevamente");
            })
        }
    }

    return (
    <Layout>
        <Container> 
            <div className="relative overflow-hidden bg-white rounded-lg mt-3 shadow-lg py-3 px-5">
                <div className={`${informacionEquipo.esquema.id === 2 ? 'bg-primary-500' : 'bg-secondary-100'} absolute transform rotate-45 text-center text-xs text-white font-semibold py-1 right-[-45px] top-[20px] w-[150px]`} >
                    {informacionEquipo.esquema.nombre}
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-x-3 gap-y-3">
                    <div>
                        <p className='w-full text-left text-2xl text-primary-500 font-bold p-2'>{informacionEquipo.nombre}</p>
                        <Carousel images={informacionEquipo.imagenEquipos}/>
                        <div className="grid grid-cols-6">
                            <FavoriteButton active={favorito} clickFn={handleFavorito} />
                            <EmailShareButton 
                                url={`https://merent.mx/rentero/${id_rentero}/equipo/${id_equipo}`} 
                                subject="Hey, revisa este equipo en MeRent.mx" 
                                body="Revisa este equipo que encontré en MeRent.mx: Equipo Nombre Equipo URL">
                                    <FaEnvelope size={22}/>
                            </EmailShareButton>

                            <FacebookShareButton
                                url={`https://merent.mx/rentero/${id_rentero}/equipo/${id_equipo}`}
                                title="Mira el equipo que encontré en MeRent.mx" >
                                <FaFacebook size={22}/>
                            </FacebookShareButton>

                            <TwitterShareButton
                                url={`https://merent.mx/rentero/${id_rentero}/equipo/${id_equipo}`}
                                title="Mira el equipo que encontré en MeRent.mx">
                                <FaTwitterSquare size={22}/>
                            </TwitterShareButton>
                            
                            <WhatsappShareButton
                                url={`https://merent.mx/rentero/${id_rentero}/equipo/${id_equipo}`}
                                title="Mira el equipo que encontré en MeRent.mx"
                                >
                                <FaWhatsappSquare size={22}/>
                            </WhatsappShareButton>

                            <LinkedinShareButton
                                url={`https://merent.mx/rentero/${id_rentero}/equipo/${id_equipo}`}
                                title="Mira el equipo que encontré en MeRent.mx">
                                <FaLinkedin size={22}/>
                            </LinkedinShareButton>
                        </div>
                    </div>
                    <div>
                        <section className="w-full p-2">
                            <p className="w-full text-right text-lg text-secondary-500 font-bold pt-2 pr-4">{informacionEquipo.rentero.nombreEmpresa} - {informacionEquipo.esquema.nombre}</p>
                            <p className="w-full text-right text-sm text-secondary-500 mt-0 pr-4">
                                {informacionEquipo.ubicacionEmpresa} 
                            </p>
                            <div className="w-full flex justify-end text-sm text-secondary-500 mt-0 pr-4">
                                <Rate defaultValue={informacionEquipo.rentero.calificacionGeneral} readOnly={false} classNames="justify-end"/>
                            </div>
                            
                            <p className="w-full text-left text-lg text-secondary-500 font-bold pt-2">Descripción</p>
                            <p className="w-full text-left text-base text-secondary-700">{informacionEquipo.descripcion}</p>
                            <p className="w-full text-left text-lg text-secondary-500 font-bold pt-2">Detalles</p>
                            <ul>
                                <li><strong>Categoría: </strong>{informacionEquipo.categoriaEquipo.nombre}</li>
                                {/* <li><strong>Sub-Categoría: </strong>{informacionEquipo.categoriaEquipo.categoriaMaestraEquipo.nombre}</li> */}
                                <li><strong>Modelo: </strong>{informacionEquipo.modelo}</li>
                                <li><strong>Horometro: </strong>{informacionEquipo.horometro}</li>
                            </ul>
                            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-x-2 gap-y-2">
                                <div className="p-1 text-seconday-100 text-lg rounded font-bold text-center mb-2">
                                    <div className="text-sm font-thin">
                                        Día
                                    </div>
                                    $ {informacionEquipo.precioDia}
                                </div>
                                <div className="p-1 text-seconday-100 text-lg rounded font-bold text-center mb-2">
                                    <div className="text-sm font-thin">
                                        Semana
                                    </div>
                                    $ {informacionEquipo.precioSemana}
                                </div>
                                <div className="p-1 text-seconday-100 text-lg rounded font-bold text-center mb-2">
                                    <div className="text-sm font-thin">
                                        Quincena   
                                    </div>
                                    $ {informacionEquipo.precioQuincena}
                                </div>
                                <div className="p-1 text-seconday-100 text-lg rounded font-bold text-center mb-2">
                                    <div className="text-sm font-thin">
                                        Mes
                                    </div>
                                    $ {informacionEquipo.precioMes}
                                </div>
                            </div>
                            { obras.length > 0 && <div className="grid grid-cols-1 gap-2 mb-4">
                                <div className=" w-full text-center">
                                    <span className="text-lg text-primary-400">
                                        Subcomunidades especiales
                                    </span>
                                </div>
                                {obras.map(({obra}, index) => {
                                    return (
                                        <>
                                        <div key={index} className="flex text-secondary-600 font-semibold text-md tracking-wider text-center justify-between">
                                            <span className="">
                                                {obra.nombre}
                                            </span>
                                            <span>
                                                {obra.ubicacion}
                                            </span>
                                        </div>
                                        </>
                                    )
                                })}
                            </div>}
                            <Compact title="Servicios">
                                <ul>
                                    {
                                        informacionEquipo.serviciosEquipos ? 
                                        informacionEquipo.serviciosEquipos.map((item, index) => {
                                            const {nombre, frecuencia} = item;
                                            return(
                                                <li key={index} className="text-sm text-left font-bold text-secondary">{nombre} - Frecuencia: {frecuencia}</li>
                                            )
                                        })
                                        :
                                        <li>Este equipo no cuenta con servicios</li>
                                    }
                                </ul>
                            </Compact>
                            <Compact title="Aditamentos">
                                <ul>
                                    {
                                        informacionEquipo.aditamentosEquipos ?
                                        informacionEquipo.aditamentosEquipos.map((item, index) => {
                                            const {nombre, frecuencia, precio} = item;
                                            return(
                                                <li key={index}>
                                                    <div className='flex flex-col'>
                                                        <p className='w-full text-xl text-center font-bold text-secondary'>{nombre}</p>
                                                        <span className="w-full text-xs text-left text-secondary">{frecuencia}</span>
                                                        <span className="w-full text-lg text-right text-secondary font-bold">{precio}</span>
                                                    </div>
                                                </li>
                                            )
                                        })
                                        :
                                        <li>Este equipo no cuenta con aditamentos</li>
                                    }
                                </ul>
                            </Compact>
                            
                            {
                                informacionEquipo.esquema.id === 1 ?
                                    <BasicButton type="button" text="Ver datos de contacto" className="bg-primary-500 hover:bg-primary-400" clickFn={() => setShowDatosContacto(true)}/>
                                :
                                    <BasicButton type="button" text="Agregar al carrito" className="bg-primary-500 hover:bg-primary-400" clickFn={() => setShowRentar(true)}/>
                            }
                            
                        </section>
                    </div>
                </div>
            </div>

            <div className="rounded-lg mt-3 bg-white shadow-lg py-3 px-5">
                <h4 className="text-primary-500">Comentarios de {informacionEquipo.rentero.nombreEmpresa}</h4>
                <div className="h-1/2 overflow-y-scroll">
                    {
                        comentarios.length > 0 ?
                        comentarios.map((item) => {
                            const {id, nombreCliente, calificacion, equipo, fecha, comentarios} = item;
                            return(
                                <Comment key={id} 
                                    name={nombreCliente} 
                                    rate={calificacion} 
                                    title={equipo} 
                                    date={fecha} 
                                    comment={comentarios} 
                                    image={''} />
                            )
                        })
                        :
                        <>
                            <p className="text-xl text-secondary-400 font-bold">Actualmente {informacionEquipo.rentero.nombreEmpresa} no cuenta con comentarios</p>
                        </>
                    }
                </div>
            </div>
            
            <ThumbnailsGroup 
                groupName={`Otros equipos de ${informacionEquipo.rentero.nombreEmpresa}`}
                description=''
                equipos={otrosEquipos}
            />

            <Modal className="max-w-2xl" title={`Información de contacto para el equipo: ${informacionEquipo.nombreEquipo}`} show={showDatosContacto} setShow={setShowDatosContacto}>
                <p className="text-sm text-gray-500 ">
                    Te proporcionamos los datos de contacto de la empresa que renta este equipo en el esquema de Renta Abierta:
                </p>
                <br/>
                <strong className="text-2xl text-primary-500">{informacionEquipo.rentero.nombreEmpresa}</strong>
                <p className="my-1 text-xl"><FaEnvelope className="text-primary-500 inline mr-2"/>{informacionEquipo.rentero.usuario.email}</p>
                <p className="my-1 text-xl"><FaMapMarkerAlt className="text-primary-500 inline mr-2"/>{informacionEquipo.rentero.direccion.direccionCompleta}</p>
                <p className="my-1 text-xl"><FaPhone className="text-primary-500 inline mr-2"/>{informacionEquipo.rentero.usuario.phoneNumber}</p>
            </Modal>

            <Modal className="max-w-2xl" title={`Rentar equipo: ${informacionEquipo.nombreEquipo}`} show={showRentar} setShow={setShowRentar}>
                <p className="text-sm text-gray-500 ">
                    Seleccione las fechas en que desea rentar este equipo
                </p>
                <div className="mt-2 grid grid-cols-12 gap-x-2 gap-y-2">
                    <div className="col-span-7 xs:col-span-12 sm:col-span-12 md:col-span-7 lg:col-span-7 xlg:col-span-7 flex justify-center ">
                        <DatePicker defaultValue={renta.datesRange} readOnly={false} onSelectRange={handleDatesRangesSelection} blockedDays={diasBloqueados}/>
                    </div>
                    <div className="text-base col-span-5 xs:col-span-12 sm:col-span-12 md:col-span-5 lg:col-span-5 xlg:col-span-5 px-2 pt-2">
                        <label className='text-primary-300 font-semibold text-sm'>Selección </label> 
                        <label className='text-primary-300 font-semibold text-sm'>({renta.totalDias} Días)</label>
                        <br/>
                        <label className='text-secondary-300 font-semibold text-lg'>{renta.fechaInicio} - {renta.fechaFin}</label>
                        <br/>
                        <br/>
                        <label className='text-primary-300 font-semibold text-sm'>Tipo de costo </label>
                        <br/>
                        <label className='text-secondary-300 font-semibold text-lg'>{renta.tipoCosto}</label>
                        <br/>
                        <br/>
                        <label className='text-primary-300 font-semibold text-sm'>Costo por día </label>
                        <br/>
                        <label className='text-secondary-300 font-semibold text-lg'>$ {renta.costoDia} MXN</label>
                        <br/>
                        <br/>
                        <label className='text-primary-300 font-semibold text-sm'>Subtotal </label>
                        <br/>
                        <label className='text-secondary-300 font-semibold text-lg'>$ {renta.subtotal} MXN</label>
                        <br/>
                        <br/>
                        <label className='text-sm'>* Costo de flete no incluido, se solicita al proceder con la renta en el carrito </label>
                    </div>
                </div>

                <div className="mt-4 flex">
                    <BasicButton type="button" clickFn={handleRenta} text={"Agregar al carrito"}/>
                </div>
            </Modal>

            <Modal className="max-w-2xl" title={`Renta agregada al carrito`} show={showSiguientePaso} setShow={setShowSiguientePaso}>
                <div className="flex items-center justify-center m-3">
                    <FaCheckCircle className="text-green-500 text-5xl"/>
                </div>
                <h4 className="text-xl text-primary-400 text-center">
                    ¡Tu intención de renta ha sido agregada al carrito!
                </h4>
                <br/>
                <p className="my-1 text-base text-center">Ya puedes visualizarla en el carrito para continuar con el proceso de la renta.</p>
                <div className="grid grid-cols-2 gap-x-3">
                    <Link to="/shopping-cart">
                        <BasicButton type="button" text="Proceder con la renta" className="bg-primary-400 text-white text-center hover:bg-primary-500 text-base col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 mt-3" clickFn={() =>{ setShowSiguientePaso(false) }}/>
                    </Link>
                    <BasicButton type="button" text="Permanecer en este sitio" className="bg-secondary-400 text-white text-center hover:bg-secondary-500 text-base col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 mt-3" clickFn={() =>{ setShowSiguientePaso(false) }}/>
                </div>
            </Modal>
        </Container>
    </Layout>
    )
}

export default EquipoTemplate;
