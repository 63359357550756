import React, { useState, Fragment, useEffect }from "react";
import FilterInput from "./UI/Inputs/FilterInput";
import { dummy_data_estados, dummy_data_ciudades, dummy_data_tipos_esquemas, dummy_data_categorias } from "../helpers/dummy_data";
import Modal from './UI/Modal';
import CardPlan from './UI/Card/CardPlan';
import ThumbnailsGroup from "./UI/Thumbnails/ThumbnailsGroup";
import toast from 'react-hot-toast';
import { merent_instance } from "../helpers/axios_helper";

const Busqueda = ({equipos}) => {
  const [esquemasDetail, setEsquemasDetail] = useState(false);
  const [currentFilter, setCurrentFilter] = useState({estado_id: 0, ciudad_id: 0, categoria_id: 0, sub_categoria_id: 0, esquema_id: 0, obra: 0, buscador: ''});
  const [estados, setEstados] = useState([]);
  const [obras, setObras] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [subCategorias, setSubCategorias] = useState([]);
  const showEsquemaDetail = () =>{
    setEsquemasDetail(true);
  }

  const [estadoCiudades, setEstadoCiudades] = useState(dummy_data_ciudades);
  useEffect(() => {
    cargaEstados();
    cargaObras();
    cargaCategorias();
  }, []);

  const cargaEstados = () => {
    merent_instance().get("api/obra").then(({data}) => {
        const new_obras = data.map(estado => {
            return {
                id: estado.id,
                value: estado.id,
                label: estado.nombre
            }
        });
        setObras(new_obras);
    }).catch((error) => {
        console.log(error);
        toast.error("Hubo un error al consultar información");
    })
  }

  const cargaObras = () => {
    merent_instance().get("api/direcciones/estados/false").then(({data}) => {
        const new_estados = data.map(estado => {
            return {
                id: estado.id,
                value: estado.id,
                label: estado.nombre
            }
        });
        setEstados(new_estados);

    }).catch((error) => {
        console.log(error);
        toast.error("Hubo un error al consultar información");
    })
  }

  const cargaCiudades = (estadoRef) => {
      merent_instance().get(`api/direcciones/ciudades/${estadoRef}/false`).then(({data}) => {
          const new_ciudades = data.map(ciudad => {
              return {
                  id: ciudad.id,
                  value: ciudad.id,
                  label: ciudad.nombre
              }
          });
          setCiudades(new_ciudades);
      }).catch((error) => {
          console.log(error);
          toast.error("Hubo un error al consultar información");
      })
  }

  const cargaCategorias = () => {
    merent_instance().get("api/categoriaequipo/maestra").then(({data}) => {
      const new_categorias = data.map(categoria => {
          return {
              id: categoria.id,
              value: categoria.id,
              label: categoria.nombre
          }
      });
      setCategorias(new_categorias);

  }).catch((error) => {
      console.log(error);
      toast.error("Hubo un error al consultar información");
  })
  }

  const cargaSubCategorias = (id) => {
    merent_instance().get(`api/categoriaequipo/maestra/${id}`).then(({data}) => {
      const new_categorias = data.map(categoria => {
          return {
              id: categoria.id,
              value: categoria.id,
              label: categoria.nombre
          }
      });
      setSubCategorias(new_categorias);

  }).catch((error) => {
      console.log(error);
      toast.error("Hubo un error al consultar información");
  })
  }

  const seleccionaEstado = (e) => {
    setCurrentFilter({...currentFilter, estado_id: e.target.value}); 
    cargaCiudades(e.target.value)
  }

  const seleccionaCategoria = (e) => {
    setCurrentFilter({...currentFilter, categoria_id: e.target.value})
    cargaSubCategorias(e.target.value);
  }

  const filters = [
    {
      name: 'Estado',
      type: 'select',
      data: estados,
      defaultValue: currentFilter.estado_id,
      setValue: seleccionaEstado
    },
    {
      name: 'Ciudad',
      type: 'select',
      data: ciudades,
      defaultValue: currentFilter.ciudad_id,
      setValue: (e) => {setCurrentFilter({...currentFilter, ciudad_id: e.target.value})}
    },
    {
      name: 'Categoria',
      type: 'select',
      data: categorias,
      defaultValue: currentFilter.categoria_id,
      setValue: seleccionaCategoria
    },
    {
      name: 'Sub Categoria',
      type: 'select',
      data: subCategorias,
      defaultValue: currentFilter.sub_categoria_id,
      setValue: (e) => {setCurrentFilter({...currentFilter, sub_categoria_id: e.target.value})}
    },
    {
      name: 'Tipo de esquema',
      type: 'select',
      data: dummy_data_tipos_esquemas,
      defaultValue: currentFilter.esquema_id,
      setValue: (e) => {setCurrentFilter({...currentFilter, esquema_id: e.target.value})},
      helpInfo: showEsquemaDetail
    },
    {
      name: 'Subcomunidad especial',
      type: 'select',
      data: obras,
      defaultValue: currentFilter.obra,
      setValue: (e) => {setCurrentFilter({...currentFilter, obra: e.target.value})},
    },
    {
      name: 'Buscador',
      type: 'text',
      defaultValue: currentFilter.buscador,
      setValue: (e) => {setCurrentFilter({...currentFilter, buscador: e.target.value})}
    }
  ];
  
  const obtenerEquiposFiltrados = (esquema) => {
    let equiposr = [];
    let equipos_esquema =  equipos.filter(e => e.esquema.id === esquema.id);

    if(currentFilter.estado_id != 0 && currentFilter.estado_id != -1)
      equipos_esquema = equipos_esquema.filter((e) => e.rentero.direccion.ciudad.estadoRef == currentFilter.estado_id);
    
    if(currentFilter.ciudad_id != 0 && currentFilter.ciudad_id != -1)
      equipos_esquema = equipos_esquema.filter((e) => e.rentero.direccion.ciudadRef == currentFilter.ciudad_id);

    if(currentFilter.categoria_id != 0 && currentFilter.categoria_id != -1)
      equipos_esquema = equipos_esquema.filter((e) => e.categoriaEquipo.categoriaMaestraEquipoRef == currentFilter.categoria_id);

    if(currentFilter.sub_categoria_id != 0 && currentFilter.sub_categoria_id != -1)
      equipos_esquema = equipos_esquema.filter((e) => e.categoriaEquipoRef == currentFilter.sub_categoria_id);

    if(currentFilter.esquema_id != 0 && currentFilter.esquema_id != -1)
      equipos_esquema = equipos_esquema.filter((e) => e.esquemaRef == currentFilter.esquema_id);

    if(currentFilter.buscador != "" && currentFilter.buscador.length > 5)
      equipos_esquema = equipos_esquema.filter((e) => e.nombre.toUpperCase().match(currentFilter.buscador.toUpperCase()));

    if(currentFilter.obra != 0 && currentFilter.obra != -1) {
      // Filtrar por obra
      console.log(equipos_esquema)
      equipos_esquema = equipos_esquema.filter((e) => e.obraEquipo.some( (obra) => obra.obraRef ==currentFilter.obra));
    }

    return equipos_esquema;
  }

  return(
    <Fragment>
        <div className="p-5">
            <form className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-5 px-3 gap-x-3 gap-y-1 mx-8">
                {
                filters.map((field) => {
                    return (
                    <FilterInput key={field.name} name={field.name} type={field.type} defaultValue={field.defaultValue} setValue={field.setValue} options={field.data} helpInfo={field.helpInfo}/>
                    )
                })
                }
            </form>
        </div>
        
        {
          dummy_data_tipos_esquemas.map((esquema) => {
            return (
              <ThumbnailsGroup 
                key={esquema.nombreEsquema}
                groupName={`Equipos en ${esquema.nombreEsquema}`}
                description={esquema.description} 
                equipos={obtenerEquiposFiltrados(esquema)}
              />
            )
          })
        }

        <Modal show={esquemasDetail} setShow={setEsquemasDetail} title="Tipos de Esquema" className="max-w-5xl">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xlg:grid-cols-2 px-4 gap-x-4 gap-y-1 mb-5">
                {
                    dummy_data_tipos_esquemas.map((esq) => {
                        return <CardPlan key={esq.id} title={esq.nombreEsquema} icon={esq.icon} important_text={esq.important_text} text={esq.beneficios}/>
                    })
                }
            </div>
        </Modal>

        </Fragment>
        )
    }
    
export default Busqueda;