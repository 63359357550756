import React, {useEffect, useState, useRef} from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "../components/UI/Container";
import Form from "../components/UI/Form";
import { form_fields_login } from '../helpers/form_fields';
import ReCAPTCHA from "react-google-recaptcha";
import Modal from '../components/UI/Modal';
import BasicButton from "../components/UI/Buttons/BasicButton";
import BasicInput from "../components/UI/Inputs/BasicInput";
import toast from 'react-hot-toast';
import { RegExpEmail } from "../helpers/constants";
import { useMediaQueries } from '@react-hook/media-query';
import { merent_instance } from "../helpers/axios_helper";
import Image from "../components/UI/LazyLoadImage";
import merent_logo from "../images/merent-logo.png";

const Logout = () => {
  const navigation = useNavigate();
  
  useEffect(() =>{
    localStorage.clear();
    setTimeout(() => {navigation("/login")}, 1500);
  }, []);

  return (
    <div className="flex flex-col h-screen justify-between">
      <Container className="flex justify-center items-center"> 
      <div className="p-5 pb-12 text-lg rounded-xl bg-white shadow-lg justify-center">
        <div className="text-center font-semibold text-3xl text-primary-500">
          <Image
              src={merent_logo}
              alt="MERENT logo"
              width={350}
            />
        </div>
        <h1 className="font-semibold text-3xl my-3 text-primary-500 text-center"> Cerrando sesión </h1>
        <p className="m-5 text-base text-center">
          Esto te redigirá al Login
        </p>
      </div>
      </Container>
    </div>
)
}

export default Logout;
