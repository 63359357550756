import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Chat from "../Chat";

const Layout = ({ children }) => {

  return (
    <div className="flex flex-col h-screen justify-between">
      <Header/>
        <main className="grow xs:mt-12 lg:mt-12 xl:mt-12 sm:mt-12 md:mt-12">{children}</main>
        <Chat/>
      <Footer/>
    </div>
  )
}

export default Layout;
