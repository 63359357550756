import React from "react";
import Layout from "../components/UI/Layout";
import Container from "../components/UI/Container";
import { Link } from "react-router-dom";
import Image from "../components/UI/LazyLoadImage";
import appstore from "../images/appstore.png";
import playstore from "../images/playstore_1.png";
import merent_logo from "../images/merent-logo.png";

const AppMerent = () => (
  <Layout>
    <Container> 
      <div className="p-5 pb-12 text-lg rounded-xl bg-white shadow-lg">
        <div className="text-center font-semibold text-3xl text-primary-500">
          <Image
          src={merent_logo}
          width={350}
          alt="MERENT logo"
        />
        </div>
        <p className="m-5 text-base text-center">
          Descarga nuestras aplicaciones a través de los siguientes enlaces
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
          <div className="text-center flex flex-col items-center">
            <label className="font-semibold text-base my-3 text-primary-500"> Aplicación para clientes en búsqueda de equipos </label>
              <Link to="/">
                <Image
                  src={appstore}
                  alt="MERENT Clientes appstore"
                  width={300}
                />
              </Link>
              <Link to="/">
              <Image
                  src={playstore}
                  alt="MERENT Clientes playstore"
                  width={300}
                />
              </Link>
          </div>
          <div className="text-center flex flex-col items-center">
            <label className="font-semibold text-base my-3 text-primary-500"> Aplicación para poner en renta mis equipos </label>
              <Link to="/">
                <Image
                  src={appstore}
                  alt="MERENT Clientes appstore"
                  width={300}
                />
              </Link>
              <Link to="/">
              <Image
                  src={playstore}
                  alt="MERENT Clientes playstore"
                  width={300}
                />
              </Link>
          </div>
        </div>
      </div>
    </Container>
  </Layout>
)


export default AppMerent;
