import { RegExpEmail, RegExpCelular, RegExpPassword } from '../helpers/constants';
import { dummy_data_estados, dummy_data_ciudades } from './dummy_data';

export const form_fields_contacto = [
    {
        title: '',
        fields: [
            {
                key: 'nombre',
                label: 'Nombre',
                type: 'text',
                isRequired: true,
                asPlaceholder: true
            },
            {
                key: 'correo',
                label: 'Correo electrónico',
                type: 'text',
                isRequired: true,
                asPlaceholder: true
            },
            {
                key: 'asunto',
                label: 'Asunto',
                type: 'text',
                isRequired: true,
                asPlaceholder: true
            },
            {
                key: 'mensaje',
                label: 'Mensaje',
                type: 'textarea',
                isRequired: true,
                asPlaceholder: true
            }
        ]
    }
];

export const form_fields_login = [
    {
        title: '',
        fields: [
            {
                key: 'email',
                label: 'Correo electronico',
                type: 'text',
                isRequired: true,
                regExp: RegExpEmail,
                asPlaceholder: true
            },
            {
                key: 'password',
                label: 'Contraseña',
                type: 'password',
                isRequired: true,
                asPlaceholder: true
            }
        ]
    }
];

export const form_fields_registro = [
    {
        title: '',
        fields: [
            {
                key: 'nombre',
                label: 'Nombre completo',
                type: 'text',
                isRequired: true,
                asPlaceholder: true,
            },
            {
                key: 'email',
                label: 'Correo electronico',
                type: 'email',
                isRequired: true,
                asPlaceholder: true,
                regExp: RegExpEmail
            },
            {
                key: 'telefono',
                label: 'Teléfono Celular',
                type: 'text',
                isRequired: true,
                asPlaceholder: true,
                regExp: RegExpCelular
            },
            {
                key: 'password',
                label: 'Contraseña',
                type: 'password',
                isRequired: true,
                asPlaceholder: true,
                regExp: RegExpPassword
            },
            {
                key: 'confirmaPassword',
                label: 'Confirma tu contraseña',
                type: 'password',
                isRequired: true,
                asPlaceholder: true,
                regExp: RegExpPassword
            }
        ]
    }
];

export const form_fields_perfil = [
    {
        title: '',
        fields: [
            {
                key: 'email',
                label: 'Correo Electronico',
                type: 'text',
                isRequired: true,
                asPlaceholder: false,
                regExp: RegExpEmail
            },
            {
                key: 'password',
                label: 'Contraseña',
                type: 'password',
                isRequired: true,
                asPlaceholder: false
            },
            {
                key: 'telefono',
                label: 'Teléfono Celular',
                type: 'text',
                isRequired: true,
                asPlaceholder: false,
                regExp: RegExpCelular
            }
        ]
    }
];


export const form_fields_perfil_direcciones = (estados, ciudades) => [
    {
        title: 'Información de la dirección',
        fields: [
            {
                key: 'nombre',
                label: 'Alias',
                type: 'text',
                isRequired: true,
                asPlaceholder: false
            },
            {
                key: 'direccionCompleta',
                label: 'Direccion completa',
                type: 'text',
                isRequired: true,
                asPlaceholder: false
            },
            {
                key: 'isPrincipal',
                label: 'Tipo',
                type: 'select',
                options: [
                    {"value":"true","label":"Principal"},
                    {"value":"false","label":"Secundaria"},
                ],
                isRequired: true,
                asPlaceholder: false
            }
        ]
    },
    {
        title: 'Direccion',
        fields: [
            {
                key: 'calle',
                label: 'Calle',
                type: 'text',
                isRequired: true,
                asPlaceholder: false
            },
            {
                key: 'numeroExterior',
                label: 'Numero Exterior',
                type: 'text',
                isRequired: true,
                asPlaceholder: false
            },
            {
                key: 'numeroInterior',
                label: 'Numero Interior',
                type: 'text',
                isRequired: false,
                asPlaceholder: false
            },
            {
                key: 'colonia',
                label: 'Colonia',
                type: 'text',
                isRequired: true,
                asPlaceholder: false
            },
            {
                key: 'estadoRef',
                label: 'Estado',
                type: 'select',
                isRequired: true,
                options: estados,
                asPlaceholder: false
            },
            {
                key: 'ciudadRef',
                label: 'Ciudad',
                type: 'select',
                isRequired: true,
                options: ciudades,
                asPlaceholder: false
            },
            {
                key: 'codigoPostal',
                label: 'C.P.',
                type: 'text',
                isRequired: true,
                asPlaceholder: false
            },

        ]
    },
    {
        title: 'Ubicacion',
        fields: [
        ]
    }
];