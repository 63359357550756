import React, {useState, useEffect} from "react";
import Layout from "../components/UI/Layout";
import Container from "../components/UI/Container";
import BusquedaOperadores from "../components/BusquedaOperadores";
import { Link } from "react-router-dom";
import Image from "../components/UI/LazyLoadImage";
import OperadoresYMecanicos from "../images/operadores_y_mecanicos.png"
import Logo from "../images/merent-logo.png"
import { merent_instance } from "../helpers/axios_helper";
import toast from "react-hot-toast";

const Operadores = () => {
  const [operadores, setOperadores] = useState([]);

  useEffect(() => {
    merent_instance().get("api/operador/catalogo").then(({data}) => {
      setOperadores(data);
    }).catch((error) => {
      toast.error("Error al cargar equipos, intenta nuevamente más tarde");
    });
  }, []);

  return (
    <Layout>
      <Container className='p-5'>
        <div className="p-5 text-lg rounded-xl bg-white shadow-lg">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
            <Image
              src={OperadoresYMecanicos}
              alt="Operadores y mecanicos"
              width="100%"
              height="20%"
              className={"object-cover h-96 w-100 m-0"}
            />
            <div className="">
              <div className="text-center font-semibold text-3xl my-4 text-primary-500">
                <Image
                src={Logo}
                width={350}
                alt="merent logo"
              />
              </div>
              <p className="m-5 text-base">
                Si eres operador o mecánico, te invitamos a formar parte de nuestra comunidad, aquí podrás ofrecer tus servicios y tener una exposición a nivel nacional, conseguirte chamba es nuestro objetivo.
                <br/><br/>
                Se parte de una comunidad donde tu experiencia y capacidad es reconocida. <Link to="https://admin.merent.mx/">Regístrate ahora</Link>.
              </p>
            </div>
            </div>   
          </div>
        <BusquedaOperadores operadores={operadores}/>    
        <br/>          
      </Container>
    </Layout>
)
}

export default Operadores;
