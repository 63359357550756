import React from 'react';
import BasicButton from "../Buttons/BasicButton";
import { Link } from "react-router-dom";
import Rate from "../Rate";
import Image from '../LazyLoadImage';
import NotFound from '../../../images/image-not-found.png'

const ThumbnailsOperadores = ({title, image, description, preview_info, location, categoria, link, button_text, calificacion, key}) => {
    return(
        <Link to={link} style={{ textDecoration: 'none' }} key={key}>
            <div className={`relative overflow-hidden cursor-pointer flex-col text-base rounded-xl bg-gray-100 shadow-lg border-2 border-gray-200 hover:border-primary-100`}>
                <div className="flex justify-center text-center text-lg p-2 bg-primary-50 rounded-t-lg text-secondary-800 h-[40px] px-10 truncate">
                    {title}
                </div>
                <div className="flex flex-row p-3 bg-white w-full text-secondary-400">
                    <div className="bg-white w-[100px] h-[80px] truncate text-secondary-400">
                    {
                        image !== undefined && image !== null && image !== "" ?
                        <Image
                        src={image.url}
                        alt={title}
                        />
                    :
                        <Image
                            src={NotFound}
                            alt={title}
                            className={"object-cover h-100 w-100"}
                        />
                    }
                    </div>
                    <div className="px-3 bg-white w-full h-[80px] truncate text-secondary-400">
                        {location}<br/>
                        <strong key={1}>{categoria}</strong><br/>
                        <Rate defaultValue={calificacion} readOnly={false} classNames="justify-start mt-0 ml-0"/>
                    </div>
                </div>
                <div className="flex flex-row px-3 py-0 h-[50px] text-ellipsis bg-white w-full text-secondary-400">
                    {description}
                </div>
                <div className="flex flex-col justify-left text-right bg-gray-300 text-secondary-500 text-sm px-2 pt-2 pb-0">
                    {preview_info}
                    <br></br>
                </div>
                <div className="flex justify-center bg-gray-300 text-base rounded-b-xl p-0">
                    <BasicButton type="button" text={button_text} className="bg-primary-400 text-white mt-0 mb-0 hover:bg-primary-500"/>
                </div>
            </div>
        </Link>
    )
}

export default ThumbnailsOperadores;