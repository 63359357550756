import React from 'react';
import BasicInput from "./BasicInput";
import { MdHelp } from "react-icons/md";

function FilterInput({name, type, defaultValue, setValue, options, helpInfo}) {
    return (
        <div className="mt-2">
            <span className="ml-3 text-secondary-600 ">{name}
            {
                helpInfo !== undefined && helpInfo !== null ? 
                <MdHelp className='inline-block ml-2 cursor-pointer text-secondary-200 ' onClick={() => { helpInfo() }}/>
                :
                ''
            }
            </span> 
            
            <BasicInput className="mt-0 border-2 border-primary-300" type={type} defaultValue={defaultValue} setValue={setValue} options={options} />
        </div>
    )
}

export default FilterInput;