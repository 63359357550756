import React, { Fragment }from "react";
import Thumbnails from './Thumbnails';

const ThumbnailsGroup = ({equipos, groupName, description}) => {
  return(
        <Fragment>
            {
                equipos !== undefined && equipos !== null && equipos.length > 0 ?
                    <Fragment>
                        <div className="p-0 mt-10 mb-5">
                            <label className='bg-primary-500 text-white text-sm px-3 py-2'>{groupName}</label>
                            <hr className='border-primary-500 border-2 mr-10 my-1'/>
                            {
                                description !== null && description !== '' ? 
                                    <p className="mx-7 my-3 text-center">{description}</p>
                                :
                                ''
                            }
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-x-3 gap-y-3 mx-8 px-5">
                            {
                                equipos.map((equipo) => {
                                    return (
                                    <Thumbnails 
                                        key={equipo.id} 
                                        title={equipo.nombre} 
                                        image={equipo.imagenEquipos[0]} 
                                        preview_info={<span key={equipo.id}><p><strong>Precio Semana</strong></p> $ {equipo.precioSemana}</span>} 
                                        link={`/rentero/${equipo.rentero.id}/equipo/${equipo.id}`}
                                        button_text="Rentar ahora"
                                        nombreEsquema={equipo.esquema.nombre}
                                        esquema={equipo.esquema.id}
                                        />
                                    )
                                })
                            }
                        </div>
                    </Fragment>
                :
                ''
            }
        </Fragment>
    )
}
    
export default ThumbnailsGroup;