import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import logo from '../../../images/merent-whatsapp.png';

const Chat = () => {
    return (
        <div>
        <FloatingWhatsApp 
            phoneNumber="+5218116605169" 
            accountName="MERENT"
            avatar={logo}
            statusMessage="Usualmente responde en 1 hora"
            chatMessage="¡Hola! 👋 ¿Cómo puedo aytudarte?"
            placeholder="Escribe un mensaje"
            buttonStyle={{bottom: "4.3rem"}}
            allowEsc
            allowClickAway
            notification
            notificationSound
        />
        </div>
    )
}

export default Chat;