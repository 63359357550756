import React, {useState, useRef, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "../components/UI/Container";
import Form from "../components/UI/Form";
import { form_fields_login } from '../helpers/form_fields';
import ReCAPTCHA from "react-google-recaptcha";
import Modal from '../components/UI/Modal';
import BasicButton from "../components/UI/Buttons/BasicButton";
import BasicInput from "../components/UI/Inputs/BasicInput";
import toast from 'react-hot-toast';
import { RegExpEmail } from "../helpers/constants";
import { useMediaQueries } from '@react-hook/media-query';
import { merent_instance } from "../helpers/axios_helper";
import Image from "../components/UI/LazyLoadImage";
import banner from "../images/banner.png";
import merent_logo from "../images/merent-logo.svg";
import { useParams } from 'react-router-dom';

const Login = () => {
  const navigation = useNavigate();
  const [captchaPass, setCaptchaPass] = useState(false);
  const [showRecover, setShowRecover] = useState(false);
  const [recoverEmail, setRecoverEmail] = useState("");
  const {olvidaContrasena} = useParams();
  const [loginFields, setLoginFields] = useState({
    email: '',
    password: '',
    source: 3
  });
  const captchaRef = useRef(null);
  
  useEffect(() => {
    console.log(olvidaContrasena)
    if(olvidaContrasena === "olvidaContrasena")
      setShowRecover(true);
  }
  ,[])
  
  const handleLogin = () => {
    if(!captchaPass) {
        toast.error("Por favor, acepta el captcha para continuar")
        return;
    }
    if(!loginFields.email.match(RegExpEmail)){
        toast.error("Ingresa una direccion de correo valida");
        return;
    }

    if(loginFields.email == '' || loginFields.email.length < 8){
        toast.error("La contraseña no es valida");
        return;
    }

    merent_instance().post("login", loginFields).then(({data}) => {
      const {email, esValido, id, mensaje, nombre, roles, token, usuario, telefono} = data;
      if(esValido) {
        const user_role = roles.filter((r) => r == "Cliente");

        if(user_role.length == 0){
          toast.error("Este sitio solamente es de clientes. Si deseas rentar un equipo, crea una cuenta")
        }
        else{
          const user_role_filtered = user_role[0];
          localStorage.setItem("tktwj", token);
          localStorage.setItem("usr_id", id);
          localStorage.setItem("usr_email", email);
          localStorage.setItem("usr_name", nombre);
          localStorage.setItem("usr_role", user_role_filtered);
          localStorage.setItem("usr_phone", telefono);
          localStorage.setItem("usr", usuario);
          
          switch(user_role_filtered){
              case 'Cliente':
                  if(localStorage.getItem('redireccionar_carrito')){
                    navigation("/shopping-cart");
                    localStorage.removeItem("redireccionar_carrito");
                  }
                  else{
                    navigation("/");
                    localStorage.removeItem("redireccionar_carrito");
                  }
                  break;
              default:
                  toast.error("Hubo un problema, favor de intentarlo más tarde")
              break;
          }
        }
      } else {
        toast.error(mensaje);
      }
    }).catch(error => {
      console.log(error);
    });
  }

  const onCaptchaPress = () => {
      setCaptchaPass(captchaRef.current.getValue());
  };

  const handleRecover = () => {
    merent_instance().get("recuperar/" + encodeURIComponent(recoverEmail)).then((data) => {
      toast.success("Se ha mandado un correo electronico con las instrucciones, por favor, continua en el");
      setShowRecover(false);
      setRecoverEmail("");
    }).catch(({response}) => {
        toast.error(response.data);
    })
  }

  return (
    <div className="flex flex-col h-screen justify-between">
      <Container className="flex justify-center items-center"> 
          <div className="p-0 text-lg rounded-lg bg-white shadow-lg w-4/5">
            <div className="grid grid-cols-1 md:grid-cols-8 lg:grid-cols-8 xl:grid-cols-8 gap-x-0 gap-y-0 mx-0 px-0">
              <div className="col-span-8 md:col-span-3 lg:col-span-3 xl:col-span-3 h-[500px]">
                <Image
                  src={banner}
                  alt="MERENT logo"
                  width={"100%"}
                  height={'100%'}
                  className={"object-cover h-full w-full"}
                />
              </div>

              <div className="flex flex-col justify-center items-center col-span-8 md:col-span-5 lg:col-span-5 xl:col-span-5 py-5">
                <div className="flex w-full justify-center">
                  <Image
                    src={merent_logo}
                    alt="MERENT logo"
                    width={150}
                  />
                </div>
                <h1 className="flex justify-center font-semibold text-3xl my-4 text-primary-500">
                  ¡Bienvenido!
                </h1>
                <ReCAPTCHA
                      className="m-1"
                      ref={captchaRef}
                      sitekey="6LfhSBslAAAAAMQgeGvz3qrWfcWoWbTT_MeUQL4d"
                      onChange={onCaptchaPress}
                  />
                <div className="w-9/12">
                  <Form 
                    formFields={form_fields_login} 
                    formState={loginFields} 
                    formModifyState={setLoginFields}
                    formSave={handleLogin}
                    inSeparatedRows={true}
                    buttonText="Iniciar sesión"
                  />
                </div>

                <h6 className="mt-4 mb-1 text-sm">¿Olvidaste tu contraseña? 
                      <b onClick={() => setShowRecover(true)} className="underline text-blue-400 cursor-pointer font-bold"> Presiona aquí</b>
                  </h6>
                  <h6 className="mb-2 text-sm">¿Aún no tienes cuenta? 
                      <Link to={'/registro'} className="underline text-blue-400 cursor-pointer font-bold"> Registrate aquí </Link> 
                  </h6>
              </div>
            </div>
          </div>   

          <Modal className="max-w-lg" title="Recuperar contraseña" show={showRecover} setShow={setShowRecover}>
              <div className="mt-2">
                  <p className="text-sm text-gray-500">
                      Ingrese su correo y recibirá las instrucciones a seguir.
                  </p>
                  <BasicInput type="email" placeholder="Correo" defaultValue={recoverEmail} setValue={(e) => setRecoverEmail(e.value)}></BasicInput>
              </div>

              <div className="mt-4 flex">
                  <BasicButton type="button" clickFn={handleRecover} text={"Enviar"}/>
              </div>
          </Modal>
      </Container>
    </div>
)
}

export default Login;
