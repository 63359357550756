import React, { useState, Fragment, useEffect }from "react";
import FilterInput from "./UI/Inputs/FilterInput";
import { dummy_data_estados, dummy_data_ciudades, dummy_data_categorias, dummy_data_operadores } from "../helpers/dummy_data";
import ThumbnailsGroupOperadores from "./UI/Thumbnails/ThumbnailsGroupOperadores";

const BusquedaOperadores = ({operadores}) => {
  const [currentFilter, setCurrentFilter] = useState({estado_id: 0, ciudad_id: 0, categoria_id: 0, esquema_id: 0, buscador: ''});
  
  const [estadoCiudades, setEstadoCiudades] = useState(dummy_data_ciudades);

  const filters = [
    {
      name: 'Estado',
      type: 'select',
      data: dummy_data_estados,
      defaultValue: currentFilter.estado_id,
      setValue: (e) => {setCurrentFilter({...currentFilter, estado_id: e.target.value}); 
                        setEstadoCiudades(dummy_data_ciudades.filter((c) => c.estado === e.target.value))}
    },
    {
      name: 'Ciudad',
      type: 'select',
      data: estadoCiudades,
      defaultValue: currentFilter.ciudad_id,
      setValue: (e) => {setCurrentFilter({...currentFilter, ciudad_id: e.target.value})}
    },
    {
      name: 'Categoria',
      type: 'select',
      data: dummy_data_categorias,
      defaultValue: currentFilter.categoria_id,
      setValue: (e) => {setCurrentFilter({...currentFilter, categoria_id: e.target.value})}
    }
  ];
  
  return(
    <Fragment>
        <div className="p-5">
            <h3 className="text-primary-400 my-1 mx-5">Catalogo de Operadores</h3>
            <form className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 px-8 gap-x-5 gap-y-1 mx-8">
                {
                filters.map((field) => {
                    return (
                    <FilterInput key={field.name} name={field.name} type={field.type} defaultValue={field.defaultValue} setValue={field.setValue} options={field.data} helpInfo={field.helpInfo}/>
                    )
                })
                }
            </form>
        </div>
        
        <ThumbnailsGroupOperadores 
            groupName={`Operadores`}
            description={`Selecciona el operador para poder ver sus datos de contacto`} 
            operadores={operadores}
        />
    </Fragment>
    )
}
    
export default BusquedaOperadores;