import React, { useState } from 'react';
import ReactStars from "react-rating-stars-component";

const Rate = ({defaultValue, readOnly, onSelectRate, classNames}) => {
    const [rating, setRating] = useState(defaultValue);

    const handleRating = (rate) => {
        if(!readOnly){
            setRating(rate);
            onSelectRate(rate);
        }
    }

    return(
        <div className={`flex m-2 ${classNames} w-full`}>
            <ReactStars
                classNames="mr-2"
                count={5}
                onChange={handleRating}
                size={18}
                activeColor="#ffd700"
                isHalf={true}
                value={rating}
                edit={readOnly}
            /><span>{rating}</span>
        </div>
    )
}

export default Rate;