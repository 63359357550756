import React, {Fragment} from 'react'
import { Dialog, Transition} from '@headlessui/react';
import { IoMdClose } from 'react-icons/io';

function Modal({show, setShow, title, children, className}) {
    return (
        <Transition appear show={show} as={Fragment}>    
            <Dialog
                as="div"
                className="fixed inset-0 z-50 overflow-y-auto border-primary-200 bg-primary-1000 "
                onClose={() => setShow(false)}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                    &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className={`inline-block w-full ${className} p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl rounded-2xl`}>
                            <Dialog.Title as="h3" className="flex justify-center text-lg text-center font-medium leading-6 text-gray-900 mb-5">
                                {title} 
                                <IoMdClose className="absolute right-10 text-secondary-200 cursor-pointer hover:text-secondary-400" onClick={() => setShow(false)}/>
                            </Dialog.Title>
                            
                            {children}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
}

export default Modal;