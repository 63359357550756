import React from 'react';

const CardPlan = ({title, icon, important_text, text, highlight, onClick}) => {
    return(
        <div onClick={onClick} className={`cursor-pointer h-30 flex-col text-2xl rounded-xl bg-gray-100 my-4 shadow-lg ${highlight ? 'border-4 border-primary-100' : ''}`}>
            <div className="flex justify-center text-center text-5xl mt-5 bg-gray-100">
                {icon}
            </div>
            <div className="flex justify-center font-semibold bg-gray-100">
                {title}
            </div>
            <div className="flex justify-center bg-primary-300 text-white text-2xl font-bold py-2">
                {important_text}
            </div>
            <div className="flex justify-center  bg-gray-100 text-base rounded-b-xl p-3">
                {text}
            </div>
        </div>
        
    )
}

export default CardPlan;