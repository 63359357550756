import React from "react";
import Layout from "../components/UI/Layout";
import Container from "../components/UI/Container";
import { Link } from "react-router-dom";
import Image from "../components/UI/LazyLoadImage";
import rentar_equipo from "../images/rentar_mis_equipos.png";
import merent_logo from "../images/merent-logo.png";

const QuieroRentarMisEquipos = () => (
  <Layout>
    <Container> 
      <div className="p-5 text-lg rounded-xl bg-white shadow-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
        <Image
            src={rentar_equipo}
            alt="MERENT"
            width="100%"
          />
          <div className="">
            <div className="text-center font-semibold text-3xl my-4 text-primary-500">
              <Image
                src={merent_logo}
                alt="MERENT logo"
                width={350}
              />
            </div>
            <div className="text-center font-semibold text-3xl my-4 text-primary-500">
              ¿Quieres poner en renta tu maquinaria o equipo?
            </div>
            <p className="m-5 text-base">
              Enlístala con nosotros y se parte de esta gran comunidad, te ayudamos a conseguir clientes de manera eficiente.
            </p>
          </div>
          </div>   
          <div className="mx-5">
            <div className="text-center font-semibold text-xl my-4 text-primary-500">
            Pasos a seguir para poner en renta mis equipos:
            </div>
            <ol className="m-5 text-base list-decimal">
              <li className="font-extrabold text-xl">
                Darte de alta en la comunidad Merent <Link to="https://admin.merent.mx/">Aquí</Link>.
              </li>
              <li>
                Enlista tus equipos llenando los campos requeridos y envía la información solicitada.
              </li>
              <li>Escoge entre nuestros 2 esquemas de Arrendamiento. 
                <ul className="ml-4 text-sm list-disc">
                  <li>
                    <label className="font-semibold text-sm my-3 text-primary-500"> Renta Segura: </label> Merent Administra la Renta, no pagas por publicar tus equipos y hasta que cerramos una operación de renta con tu autorización, Merent cobra una Comisión. <Link to="https://admin.merent.mx/">Contrato de Comisiòn Mercantil</Link>.
                  </li>
                  <li>
                    <label className="font-semibold text-sm my-3 text-primary-500"> Renta Abierta: </label> Este Esquema opera bajo una suscripción mensual en la que los datos de contacto del Usuario-Arrendador serán visibles a los integrantes de la comunidad, los clientes contactarán directamente al arrendador y acordarán los términos y condiciones de la operación conjuntamente.
                  </li>
                </ul>
              </li>
              <li>
                Espera confirmación por parte de nuestro equipo.
              </li>
              <li>
                Recibe aviso de Autorización y listo, deja que la aplicación trabaje por ti.
              </li>
            </ol>
          </div>
        </div>
    </Container>
  </Layout>
)

export default QuieroRentarMisEquipos;
