import React, {useState, useRef} from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "../components/UI/Container";
import Form from "../components/UI/Form";
import { form_fields_registro } from '../helpers/form_fields';
import ReCAPTCHA from "react-google-recaptcha";
import { useMediaQueries } from '@react-hook/media-query';
import toast from 'react-hot-toast';
import { merent_instance } from "../helpers/axios_helper";
import Image from "../components/UI/LazyLoadImage";
import banner from "../images/banner.png";
import merent_logo from "../images/merent-logo.svg";

const Registro = () => {
  const navigation = useNavigate();
  const [captchaPass, setCaptchaPass] = useState(false);
  const captchaRef = useRef(null);
  const [registroFields, setRegistroFields] = useState({
      nombre: '',
      email: '',
      telefono: '',
      password: '',
      confirmaPassword: ''
  });
  const [returnedMessage, setReturnedMessage] = useState('');
  const [returnedError, setReturnedError] = useState(false);

  const {matches} = useMediaQueries({
    width: '(min-width: 1000px)'
  });

  const handleRegistro = () => {
    merent_instance().post("api/Usuarios/cliente", registroFields).then(({data}) => {
          setReturnedMessage(data);
          setReturnedError(false);
          //toast.success(data);
          setTimeout(() => {navigation("/login")}, 2000);
      }).catch((error) => {
          setReturnedMessage(error.response.data);
          setReturnedError(true);
          //toast.error(error.response.data);
          if(error.response.status === 409)
            setTimeout(() => {navigation("/login")}, 4000);
      });
  }

  const onCaptchaPress = () => {
      setCaptchaPass(captchaRef.current.getValue());
  };

  return (
    <div className="flex flex-col h-screen justify-between">
      <Container className="flex justify-center items-center"> 
          <div className="p-0 text-lg rounded-lg bg-white shadow-lg w-4/5">
            <div className="grid grid-cols-1 md:grid-cols-8 lg:grid-cols-8 xl:grid-cols-8 gap-x-0 gap-y-0 mx-0 px-0">
              <div className="col-span-8 md:col-span-3 lg:col-span-3 xl:col-span-3">
                <Image
                  src={banner}
                  alt="MERENT logo"
                  width={"100%"}
                  height={'100%'}
                  className={"object-cover h-full w-full"}
                />
              </div>

              <div className="flex flex-col justify-center items-center col-span-8 md:col-span-5 lg:col-span-5 xl:col-span-5 py-5">
                <div className="flex w-full justify-center">
                  <Image
                    src={merent_logo}
                    alt="MERENT logo"
                    width={150}
                  />
                </div>
                <h1 className="flex justify-center font-semibold text-3xl my-4 text-primary-500">
                  Crea una cuenta
                </h1>
                <ReCAPTCHA
                      className="m-1"
                      ref={captchaRef}
                      sitekey="6LfhSBslAAAAAMQgeGvz3qrWfcWoWbTT_MeUQL4d"
                      onChange={onCaptchaPress}
                  />
                <div className="w-9/12">
                  <Form 
                    formFields={form_fields_registro} 
                    formState={registroFields} 
                    formModifyState={setRegistroFields}
                    formSave={handleRegistro}
                    inSeparatedRows={true}
                    buttonText="Registrarme"
                    autoCompleteField={false}
                  />
                  {
                    returnedMessage !== '' ?
                    <div>
                      <h4 className={`flex justify-center text-center font-semibold text-base my-4 ${returnedError ? 'text-red-500' : 'text-green-500'}`}>
                        {returnedMessage}
                      </h4>
                    </div>
                    :
                    ''
                  }                  
                </div>
                  <h6 className="mb-2 text-sm">¿Ya tienes una cuenta? 
                      <Link to={'/login'} className="underline text-blue-400 cursor-pointer font-bold"> Inicia sesión </Link> 
                  </h6>
              </div>
            </div>
          </div>   
      </Container>
    </div>
)
}

export default Registro;
