import React, {useState, useEffect, Fragment} from "react";
import { Link } from "react-router-dom";
import { MdLogin, MdLogout } from "react-icons/md";
import { FaShoppingCart, FaAngleDown, FaUserCircle } from "react-icons/fa";
import Image from "../LazyLoadImage";
import merent_logo from "../../../images/merent-logo.svg";
import { merent_instance } from "../../../helpers/axios_helper";

const isBrowser = typeof window !== "undefined";

const Header = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [myCarrito, setMyCarrito] = useState([]);
  const [carritoTotal, setCarritoTotal] = useState(0);
  const [userLogged, setUserLogged] = useState(0);

  useEffect(() => {
    const carrito = localStorage.getItem("carrito");
    let count = 0;
    if(carrito){
      setMyCarrito(JSON.parse(carrito));
      count = JSON.parse(carrito).length;
    } else {
      count = 0;
    }
    cargarCarritoRemoto(count);
    let usr_id = localStorage.getItem("usr_id");
    setUserLogged(usr_id);
  }, []);
  
  const cargarCarritoRemoto = (lengthActual) => {
    const usr_id = localStorage.getItem("usr_id");
    if(usr_id !== undefined && usr_id !== null && usr_id > 0){
      merent_instance().get(`/api/rentas/cliente/${usr_id}`)
      .then(({data}) => {
        setCarritoTotal(lengthActual + data.length)
      })
      .catch((error) => {
      })
    }

  }

  return (
    <div className="fixed z-50 w-full shadow-md shadow-secondary-100/50">
      <nav className="rs-navbar rs-navbar-default">
        <Link className="rs-navbar-brand" to="/">
          <Image
            src={merent_logo}
            alt="MERENT logo"
            width={"100%"}
            height={'100%'}
            className={"object-cover h-full w-full"}
          />
        </Link>

        <div className="rs-nav rs-nav-default rs-nav-horizontal xs:hidden sm:hidden md:hidden lg:hidden">
          <Link className="rs-navbar-item" to="/quienes-somos">Quiénes somos</Link>
          <Link className="rs-navbar-item" to="/rentar-un-equipo">Rentar un equipo</Link>
          <Link className="rs-navbar-item" to="/poner-en-renta-mis-equipos">Poner en renta mis equipos</Link>
          <Link className="rs-navbar-item" to="/operadores">Operadores y Mecánicos</Link>
          <Link className="rs-navbar-item" to="/app-merent">App MERENT</Link>
          <Link className="rs-navbar-item" to="/favoritos">Favoritos</Link>
          <Link className="rs-navbar-item" to="/contacto">Contacto</Link>
        </div>

        <span className="rs-nav rs-nav-default rs-nav-horizontal xl:hidden xxl:hidden">
          <div className="rs-dropdown rs-dropdown-placement-bottom-start rs-dropdown-open">
          <div className="rs-navbar-item rs-navbar-item cursor-pointer" to="/" onKeyDown={() => setToggleMenu(!toggleMenu)} onClick={() => setToggleMenu(!toggleMenu)}>Menu <FaAngleDown/></div>
          {
            toggleMenu ? 
              <div className="rs-dropdown-menu rs-navbar-default">
                <Link className="hover:no-underline" to="/quienes-somos"><div className=" hover:bg-gray-200 cursor-pointer px-5 py-2 text-secondary-500">Quienes somos</div></Link>
                <Link className="hover:no-underline" to="/rentar-un-equipo"><div className=" hover:bg-gray-200 cursor-pointer px-5 py-2 text-secondary-500">Rentar un equipo</div></Link>
                <Link className="hover:no-underline" to="/poner-en-renta-mis-equipos"><div className=" hover:bg-gray-200 cursor-pointer px-5 py-2 text-secondary-500">Poner en renta mis equipos</div></Link>
                <Link className="hover:no-underline" to="/operadores"><div className=" hover:bg-gray-200 cursor-pointer px-5 py-2 text-secondary-500">Operadores y Mecánicos</div></Link>
                <Link className="hover:no-underline" to="/app-merent"><div className=" hover:bg-gray-200 cursor-pointer px-5 py-2 text-secondary-500">App MERENT</div></Link>
                <Link className="hover:no-underline" to="/favoritos"><div className=" hover:bg-gray-200 cursor-pointer px-5 py-2 text-secondary-500">Favoritos</div></Link>
                <Link className="hover:no-underline" to="/contacto"><div className=" hover:bg-gray-200 cursor-pointer px-5 py-2 text-secondary-500">Contacto</div></Link>
                {
                  userLogged === undefined || userLogged === null || userLogged === 0 ?
                    <Link className="hover:no-underline" to="/login"><div className=" hover:bg-gray-200 cursor-pointer px-5 py-2 text-secondary-500 flex items-center">Login <span className="mx-1"><MdLogin/></span></div></Link>
                  :
                  <Fragment>
                    <Link className="hover:no-underline" to="/perfil"><div className=" hover:bg-gray-200 cursor-pointer px-5 py-2 text-secondary-500 flex items-center"><span className="mr-1"><FaUserCircle/></span> Mi perfil</div></Link>
                    <Link className="hover:no-underline" to="/logout"><div className=" hover:bg-gray-200 cursor-pointer px-5 py-2 text-secondary-500 flex items-center">Logout <span className="mx-1"><MdLogout/></span></div></Link>
                  </Fragment>
                }
                
                
              </div>
            :
            ''
          }
          </div>
        </span>

        {
          userLogged === undefined || userLogged === null || userLogged === 0 ?
            <div className="rs-nav rs-nav-default rs-nav-horizontal float-right">
              <Link to="/shopping-cart" className="rs-navbar-item">
                <FaShoppingCart className="text-xl"/><div className="rs-badge-content mt-0">{carritoTotal}</div>
              </Link>
              <Link to="/login" className="m-0 px-2 py-2 h-[56px] rounded-none text-inherit float-left bg-transparent relative overflow-hidden flex hover:bg-gray-200 hover:no-underline cursor-pointer hover:text-secondary-500 text-secondary-500 items-center xs:hidden sm:hidden md:hidden lg:hidden"><span className="mr-1"><MdLogin/></span> Login</Link>
            </div>
          :
            <div className="rs-nav rs-nav-default rs-nav-horizontal float-right">
              <Link to="/shopping-cart" className="rs-navbar-item">
                <FaShoppingCart className="text-xl"/><div className="rs-badge-content mt-0">{carritoTotal}</div>
              </Link>
              <Link to="/perfil" className="m-0 px-2 py-2 h-[56px] rounded-none text-inherit float-left bg-transparent relative overflow-hidden flex hover:bg-gray-200 hover:no-underline cursor-pointer hover:text-secondary-500 text-secondary-500 items-center xs:hidden sm:hidden md:hidden lg:hidden"><span className="mr-1"><FaUserCircle/></span> Mi perfil</Link>
              <Link to="/logout" className="m-0 px-2 py-2 h-[56px] rounded-none text-inherit float-left bg-transparent relative overflow-hidden flex hover:bg-gray-200 hover:no-underline cursor-pointer hover:text-secondary-500 text-secondary-500 items-center xs:hidden sm:hidden md:hidden lg:hidden">Logout <span className="mx-1"><MdLogout/></span></Link>
            </div>
        }
      </nav>
    </div>
  )
}

export default Header;
