import React, {useState} from "react";
import Layout from "../components/UI/Layout";
import Container from "../components/UI/Container";
import Form from "../components/UI/Form";
import { form_fields_contacto } from '../helpers/form_fields';
import toast from 'react-hot-toast';
import { FaEnvelope, FaMapMarkerAlt, FaPhone,  FaFacebookSquare, FaTwitterSquare, FaInstagramSquare } from "react-icons/fa";

const social_media = [
  {
    name: 'Facebook',
    link: 'https://www.facebook.com/',
    icon: <FaFacebookSquare/>
  },
  {
    name: 'Twitter',
    link: 'https://twitter.com/',
    icon: <FaTwitterSquare/>
  },
  {
    name: 'Instagram',
    link: 'https://www.instagram.com/',
    icon: <FaInstagramSquare/>
  }
];

const Contacto = () => {
  const [contactoFields, setContactoFields] = useState({
      nombre: '',
      correo: '',
      asunto: '',
      mensaje: ''
  });

  const saveContacto = () => {
      toast.success("Mensaje enviado");
  };

  return (
    <Layout>
      <Container className="flex justify-center items-center"> 
          <div className="p-5 text-lg rounded-lg bg-white shadow-lg w-4/5 ">
            <div className="flex justify-center font-semibold text-3xl my-4 text-primary-500">
                Contacto
            </div>
            <div className="grid grid-cols-1 md:grid-cols-8 lg:grid-cols-8 xl:grid-cols-8 gap-x-3 gap-y-3 mx-4 px-5">
              <div className="col-span-8 md:col-span-3 lg:col-span-3 xl:col-span-3">
                <strong>Empresa SA de CV </strong>
                <p className="my-1"><FaEnvelope className="text-primary-500 inline mr-2"/> correocontacto@merent.com</p>
                <p className="my-1"><FaMapMarkerAlt className="text-primary-500 inline mr-2"/> Dirección de la empresa</p>
                <p className="my-1"><FaPhone className="text-primary-500 inline mr-2"/> Teléfono</p>

                <div className="pl-6 mt-8 flex justify-between w-1">
                  {
                    social_media.map((item) => {
                      return <a key={item.name} title={item.name} href={item.link} className="text-primary-400 mx-2 text-2xl hover:text-primary-300 hover:font-bold" target="_blank">{item.icon}</a>
                    })
                  }
                </div>
              </div>
              <div className="col-span-8 md:col-span-5 lg:col-span-5 xl:col-span-5">
                <Form 
                  formFields={form_fields_contacto} 
                  formState={contactoFields} 
                  formModifyState={setContactoFields}
                  formSave={saveContacto}
                  inSeparatedRows={true}
                  buttonText="Enviar mensaje"
                />
              </div>
            </div>
          </div>   
      </Container>
    </Layout>
)
}

export default Contacto;
