import React from "react";
import { Toaster } from 'react-hot-toast';

function Container({children, className}) {
    return(
        <div className="bg-construction-pattern w-full h-full">
            <main className={`bg-construction-pattern bg-contain bg-origin-content w-full h-full p-5 ${className}`}>
                {children}
            </main>
            <Toaster
                position="top-right"
                toastOptions={{
                  // Define default options
                  className: "",
                  duration: 4000,
                  style: {
                    background: "#363636",
                    color: "#fff",
                  },
                }}
              />
        </div>
    )
}

export default Container;