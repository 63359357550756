import React, {useState} from 'react';
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

function BasicInput({type, placeholder, defaultValue, setValue, options, disabled, maxFilesQty, accept, className}) {
   
    const [showPassword, setShowPassword] = useState(false);
    
    return (
        <>
        {
            type === 'select' ? 
                <select className={`rounded bg-gray-100 px-4 h-9 py-2 my-2 w-full m-2 text-sm ${className}`}
                    value={defaultValue} 
                    onChange={setValue}
                    disabled={disabled}
                >
                    <option value={-1}>Selecciona...</option>
                    {
                        options.map((opt) => {
                            return <option key={opt.value} value={opt.value}>{opt.label}</option>
                        })
                    }
                </select>
            : type === 'password' ? 
                <div className='flex my-2 w-full m-2'>
                    <input className="border-none rounded-l-sm bg-gray-100 px-4 h-9 text-sm flex-auto" 
                        type={showPassword ? "text" : "password"} 
                        placeholder={placeholder}
                        defaultValue={defaultValue} 
                        onChange={setValue}  
                        disabled={disabled}
                        multiple={maxFilesQty !== undefined && maxFilesQty !== null && maxFilesQty > 1 ? true : false}
                        accept={accept}
                    />
                    <button type="button" className='bg-gray-400 text-white h-full text-lg w-12 flex justify-center py-2 border-none rounded-r-sm hover:bg-gray-500 focus:border-none'
                        onClick={()=>{setShowPassword(!showPassword)}}
                        >
                        {
                            !showPassword ? <BsFillEyeFill/> : <BsFillEyeSlashFill/>
                        }
                    </button>
                </div>
            :
                <input className={`rounded bg-gray-100 px-4 h-9 my-2 w-full m-2 text-sm ${className}`}
                    type={type} 
                    placeholder={placeholder}
                    defaultValue={defaultValue} 
                    onChange={setValue}  
                    disabled={disabled}
                    multiple={maxFilesQty !== undefined && maxFilesQty !== null && maxFilesQty > 1 ? true : false}
                    accept={accept}
                />
        }
        
        </>
    )
}

export default BasicInput;